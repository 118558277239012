import React from 'react'
import { useStyles } from './ProductDetailsImage.style'
import { Box } from '@material-ui/core'

export interface ProductDetailsImage {
  id: number
  imgUrl: string
}

interface ProductDetailsImageProps {
  detailImage: ProductDetailsImage
}
const ProductDetailsImage = ({ detailImage }: ProductDetailsImageProps) => {
  const classes = useStyles({ ...detailImage })
  return (
    <Box className={classes.productImageWrapper}>
      <img
        className={classes.productImage}
        src={`${detailImage.imgUrl}`}
        alt=''
      ></img>
    </Box>
  )
}

export default ProductDetailsImage
