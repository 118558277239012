// import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ActionTypes } from '../../ProductModule/actions'
import { RootState } from '../../ProductModule/reducers'

const useError = () => {
  const error = useSelector((state: RootState) => state.errorReducer)
  const dispatch = useDispatch()

  const addError = (message: string) => {
    message && dispatch({ type: ActionTypes.ADD_ERROR, payload: { message } })
  }
  const removeError = () => {
    dispatch({ type: ActionTypes.REMOVE_ERROR })
  }

  // React.useEffect(() => {
  //   console.log(error)
  // }, [error])
  return { error, addError, removeError }
}

export default useError
