import { makeStyles, createStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  (theme) => {
    return createStyles({
      carouselWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        height: '100%',
        width: '90%',
        margin: 'auto',
      },
      indicatorWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 30,
        marginBottom: 20,
      },
      indicatorDots: {
        /** Position */
        margin: '0 2.5px 0 2.5px',

        /** Box */
        display: 'inline-block',
        height: 13,
        width: 13,

        /** Typo */
        textAlign: 'center',

        /** Visual */
        border: '1px solid black',
        borderRadius: '100%',

        /** Misc */
        cursor: 'pointer',
        opacity: 0.35,
        transition: 'all 0.5s ease-in-out',
        '&:hover': {
          opacity: 0.75,
          backgroundColor: theme.palette.common.black,
        },
      },
      indicator: {
        /** Position */
        /** Box */
        display: 'inline-block',
        height: 5,
        width: '10%',
        /** Typo */
        textAlign: 'center',
        /** Visual */
        backgroundColor: theme.palette.primary.dark,
        /** Misc */
        cursor: 'pointer',
        transition: 'all 0.5s ease-in-out',
        '&:first-child': {
          borderRadius: '2px 0 0 2px',
        },
        '&:last-child': {
          borderRadius: '0 2px 2px 0',
        },
        '&:hover': {
          backgroundColor: theme.palette.common.black,
          opacity: 0.75,
        },
      },
      indicatorActive: {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },
      main: {
        /** Box */
        height: '100%',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: '0 10px 0 10px',
      },
      arrow: {
        /** Box */
        display: 'flex',
        // flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      },
      flexComponentWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'stretch',
        height: '100%',
        width: '100%',
      },
      blockComponentWrapper: {
        position: 'relative',
        display: 'block',
        width: '90%',
        height: '96%',
      },
    })
  },
  { name: 'carousel', index: 1 }
)
