import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      background: '#000',
      height: 'auto',
      backgroundSize: 'cover',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    bannerTextWrapper: {
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    bannerText: {
      width: '100%',
      margin: '3% 3% 1.5% 3%',
      textAlign: 'center',
    },
    header: {
      marginBottom: 15,
      color: '#fff',
      fontSize: 30,
      fontWeight: 600,
      lineHeight: '40px',
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        fontSize: 50,
        lineHeight: '55px',
      },
    },
    text: {
      marginBottom: 15,
      color: '#fff',
      fontSize: 16,
      lineHeight: '26px',
      fontWeight: 400,
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        fontSize: 20,
        lineHeight: '30px',
      },
    },
    subText: {
      marginBottom: 15,
      color: '#999',
      fontSize: 12,
      lineHeight: '20px',
      fontWeight: 300,
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        fontSize: 14,
        lineHeight: '22px',
      },
    },
    buttonLabel: {
      width: 'fit-content',
      margin: '0 auto 15px auto',
      color: theme.palette.primary.main,
      padding: '5px 15px',
      border: `1px solid ${theme.palette.primary.main}`,
      textTransform: 'uppercase',
      fontSize: 11,
      fontWeight: 300,
      textUnderlinePosition: 'under',
      cursor: 'pointer',
      transition: 'all ease-out 0.3s',
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        fontSize: 14,
      },
      '&:hover': {
        color: '#fff',
        border: `1px solid ${theme.palette.primary.light}`,
      },
    },
    repositionImage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    bannerImageWrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    bannerImage: {
      marginBottom: 30,
      width: '100%',
      maxWidth: '90vw',
      objectFit: 'contain',
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        width: '90%',
        maxWidth: '50vw',
      },
    },
    lightTheme: {
      background: '#f3f4f6',
      '& $header': {
        color: theme.palette.primary.dark,
      },
      '& $text': {
        color: theme.palette.primary.dark,
      },
      '& $subText': {
        color: theme.palette.primary.dark,
      },
      '& $buttonLabel': {
        '&:hover': {
          color: theme.palette.primary.dark,
          border: `1px solid ${theme.palette.primary.dark}`,
        },
      },
    },
  }),
  { name: 'banner', index: 1 }
)
