import React from 'react'
import { Box, Icon, DialogContent, Dialog } from '@material-ui/core'
import CategoryTree from '../../CommonModule/CategoryTree/CategoryTree.component'
import { Category } from '../../ProductModule/DataModels/Categories'
import useCategories from '../../ProductModule/hooks/useCategories'
import { useStyles } from './CategorySelector.style'
import useDevice, { ScreenSizes } from '../../CommonModule/hooks/useDevice'
import '../../i18n'
import { useTranslation } from 'react-i18next'

const CategorySelector = (props: any) => {
  const device = useDevice()
  const classes = useStyles()
  const { content, findCategoryById } = useCategories()
  const [open, setOpen] = React.useState(false)
  const [selectedCategory, setSelectedCategory] = React.useState(Object)
  const { t } = useTranslation()

  //Rerender when state of selected value changes
  React.useEffect(() => {
    if (props.selectedValue[0] !== '') {
      setSelectedCategory(findCategoryById(props.selectedValue[0]))
    }
  }, [props.selectedValue])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleChange(event.target.value)
  }
  const handleOpenModal = () => {
    setOpen(!open)
  }

  return (
    <Box className={classes.root}>
      {selectedCategory.name !== undefined && props.selectedValue[0] !== '' && (
        <Box display='inline-block' className={classes.sectionLabel}>
          <Box display='inline-block' className={classes.selectedCategoryName}>
            {selectedCategory.name}
          </Box>
          {t('common.selected')}
        </Box>
      )}
      <Box className={classes.button} onClick={handleOpenModal}>
        {t('common.select')}
      </Box>
      <Dialog
        fullScreen={device.getScreenSize() < ScreenSizes.sm}
        open={open}
        onClose={handleOpenModal}
        aria-labelledby='responsive-dialog-title'
      >
        <Box className={classes.popupCloseButton} onClick={handleOpenModal}>
          <Icon style={{ cursor: 'pointer' }}>highlight_off</Icon>
        </Box>
        <DialogContent className={classes.popupContent}>
          {content.categories
            .filter((c) => c.path === '')
            .map((cate: Category, index) => (
              <CategoryTree
                key={index}
                category={cate}
                level={0}
                selectedValues={props.selectedValue}
                handleChange={handleChange}
              />
            ))}
        </DialogContent>
        <Box className={classes.popupButtonsWrapper}>
          <Box className={classes.popupSubmitButton} onClick={handleOpenModal}>
            {t('common.submit')}
          </Box>
        </Box>
      </Dialog>
    </Box>
  )
}
export default CategorySelector
