import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(
  (theme) => {
    return {
      wrapper: {
        textAlign: 'center',
        marginBottom: 35,
      },
      title: {
        position: 'relative',
        zIndex: 1,
        fontSize: 20,
        fontWeight: 700,
        textTransform: 'uppercase',
        letterSpacing: '1px',
        textAlign: 'center',
        transition: 'all 0.3s linear',
        '&:hover': {
          '& $innerMark': {
            width: '100%',
          },
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 24,
        },
      },
      innerMark: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        zIndex: -1,
        height: 3,
        width: 30,
        background: theme.palette.primary.main,
        transition: 'all 0.3s ease-out',
      },
    }
  },
  { name: 'title', index: 1 }
)

const Title = (props: { text: string }) => {
  const { text } = props
  const classes = useStyles()
  return (
    <>
      <div className={classes.wrapper}>
        <span className={classes.title}>
          <div className={classes.innerMark}></div>
          {text}
        </span>
      </div>
    </>
  )
}

export default Title
