import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  (theme) => {
    return {
      cardWrapper: {
        width: '100%',
        padding: 10,
        boxSizing: 'border-box',
        [theme.breakpoints.up('sm')]: {
          width: '50%',
        },
        [theme.breakpoints.up('md')]: {
          width: '33.3%',
        },
        [theme.breakpoints.up('lg')]: {
          width: '25%',
        },
      },
      card: {
        /** Position */
        position: 'relative',
        /** Box-model */
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
        /** Misc */
        transition: 'all ease-out 0.3s',

        '&:hover': {
          boxShadow: '0 0 10px 0 rgba(0,0,0,0.7)',
          // reference to a rule named "btn" in the same stylesheet
          '& $hidden': {
            opacity: 1,
          },
          '& $image': {},
        },
      },
      buttonWrapper: {
        /** Position */
        position: 'absolute',
        top: 0,
        left: 0,
        /** Box */
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        /** Typo */
        alignItems: 'center',
        justifyContent: 'center',

        '& button': {
          marginBlockStart: '10px',
          marginBlockEnd: '10px',
          transition: 'all 0.5s ease',
        },
      },
      hidden: {
        opacity: 0,
      },
      imageWrapper: {
        position: 'relative',
        /** Box */
        width: '100%',
        paddingTop: '100%',
      },
      image: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        /** Box */
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        /** Misc */
        cursor: 'pointer',
        transition: 'all 0.5s ease',
      },
      contentWrapper: {
        padding: '15px 0 50px 0',
        transition: 'all ease-out 0.3s',
      },
      productName: {
        /** Typo */
        fontSize: 13,
        padding: '0 10px',

        [theme.breakpoints.up('sm')]: {
          fontSize: 15,
        },
      },
      price: {
        width: '100%',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: '10px',
        fontSize: 14,
        color: '#ffb400',
        fontWeight: 'bold',

        [theme.breakpoints.up('sm')]: {
          fontSize: 16,
        },
      },
    }
  },
  { index: 1 }
)
