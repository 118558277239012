import React, { Fragment, useEffect } from 'react'
import ProductDetails from '../components/ProductDetails'
import { Box } from '@material-ui/core'
import useProduct from '../hooks/useProduct'
import { useParams } from 'react-router-dom'
import useScroll from '../hooks/useScroll'
import LoadingSpinner from '../../CommonModule/LoadingSpinner'

const ProductDetailsPage = () => {
  const products = useProduct().content.products
  const params: { id: string } = useParams()
  const selectedProduct = products.find(
    (product: any) => product.id === params.id
  )
  const { scrollToTop } = useScroll()

  useEffect(() => {}, [selectedProduct])

  if (selectedProduct !== undefined) {
    return (
      <Fragment>
        {scrollToTop()}
        <Box marginTop={5} marginBottom={10} marginLeft='5%' marginRight='5%'>
          <ProductDetails product={selectedProduct}></ProductDetails>
        </Box>
      </Fragment>
    )
  } else {
    return (
      <Box
        height='80vh'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <LoadingSpinner open={true} />
      </Box>
    )
  }
}

export default ProductDetailsPage
