import React, { useState } from 'react'
import { Box, Icon, Collapse } from '@material-ui/core'
import { useStyles } from './CMS.style'
import Hamburger from '../../CommonModule/Hamburger/Hamburger.component'
import Drawer from '@material-ui/core/Drawer'
import clsx from 'clsx'
import { Switch, Route } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import CategoryEdit from '../CategoryEdit/CategoryEdit.component'
import ProductEdit from '../ProductEdit/ProductEdit.component'
import ProductList from '../ProductList/ProductList.component'
import CategoryList from '../CategoryList/CategoryList.component'
import ImageGalleryEdit from '../ImageGalleryEdit/ImageGalleryEdit.component'
import Dashboard from '../Dashboard/Dashboard.component'
import useDevice, { ScreenSizes } from '../../CommonModule/hooks/useDevice'
import useAuth from '../Auth/hooks/useAuth'
import '../../i18n'
import { useTranslation } from 'react-i18next'

const CMS = () => {
  const classes = useStyles()
  const device = useDevice()
  const { logOut } = useAuth()
  const [open, setOpen] = useState(true)
  const { t, i18n } = useTranslation()

  const handleDrawer = () => {
    if (device.getScreenSize() < ScreenSizes.md) setOpen(!open)
  }

  const routesInfo = [
    { path: '/cms/', component: Dashboard, exact: true },
    { path: '/cms/categories/:id', component: CategoryEdit, exact: true },
    { path: '/cms/products/:id', component: ProductEdit, exact: true },
    { path: '/cms/products', component: ProductList, exact: true },
    { path: '/cms/categories', component: CategoryList, exact: true },
    { path: '/cms/image-gallery', component: ImageGalleryEdit, exact: true },
  ]
  const navLinks = [
    { label: `${t('common.dashboard')}`, path: '/cms/', exact: true },
    {
      label: `${t('common.manageCategories')}`,
      path: '/cms/categories',
      exact: true,
    },
    {
      label: `${t('common.manageProducts')}`,
      path: '/cms/products',
      exact: true,
    },
    {
      label: `${t('common.frontpageUI')}`,
      path: '',
      exact: true,
      children: [
        {
          label: `${t('common.galleryImages')}`,
          path: '/cms/image-gallery',
          exact: true,
        },
      ],
    },
  ]

  const languages = [
    { label: `${t('common.vietnamese')}`, code: 'vi' },
    { label: `${t('common.english')}`, code: 'en' },
  ]

  const SideLanguageLink = () => {
    const [openDropdown, setOpenDropdown] = useState(false)
    const handleDropdown = () => {
      setOpenDropdown(!openDropdown)
    }
    const changeLanguage = (lng: string) => {
      i18n.changeLanguage(lng)
    }
    return (
      <>
        <Box
          className={`${classes.tab} ${classes.tabDropdown}`}
          onClick={handleDropdown}
        >
          {t('common.selectLanguage')}
          <Icon className={classes.dropdownIcon}>
            {openDropdown
              ? 'keyboard_arrow_up_icon'
              : 'keyboard_arrow_down_icon'}
          </Icon>
        </Box>
        <Collapse in={openDropdown} timeout='auto' unmountOnExit>
          {languages.map((language, index) => (
            <Box
              key={index}
              className={classes.tab}
              onClick={() => changeLanguage(language.code)}
            >
              {language.label}
            </Box>
          ))}
        </Collapse>
      </>
    )
  }

  interface SideNavLink {
    label: string
    path: string
    exact: boolean
    children?: SideNavLink[]
  }

  const SideNavLink = ({ label, path, exact, children }: SideNavLink) => {
    const [openDropdown, setOpenDropdown] = useState(false)
    const handleDropdown = () => {
      setOpenDropdown(!openDropdown)
    }
    return (
      <>
        {children && children.length > 0 ? (
          <>
            <Box
              className={`${classes.tab} ${classes.tabDropdown}`}
              onClick={handleDropdown}
            >
              {label}
              <Icon className={classes.dropdownIcon}>
                {openDropdown
                  ? 'keyboard_arrow_up_icon'
                  : 'keyboard_arrow_down_icon'}
              </Icon>
            </Box>
            <Collapse in={openDropdown} timeout='auto' unmountOnExit>
              {children.map((child, index) => (
                <NavLink
                  key={index}
                  activeClassName={classes.activeTab}
                  className={classes.tab}
                  to={child.path}
                  exact={child.exact}
                  onClick={handleDrawer}
                >
                  {child.label}
                </NavLink>
              ))}
            </Collapse>
          </>
        ) : (
          <NavLink
            activeClassName={classes.activeTab}
            className={classes.tab}
            to={path}
            exact={exact}
            onClick={handleDrawer}
          >
            {label}
          </NavLink>
        )}
      </>
    )
  }

  return (
    <>
      <Box className={classes.navBar}>
        <Box className={classes.logoSection}>
          Nero<span style={{ color: '#ffb400' }}>Core</span>
        </Box>
        <Box className={classes.otherSection}>
          <Box className={classes.hamburgerWrapper}>
            <Hamburger
              color={'black'}
              isOpen={open}
              desktop={false}
              onClick={handleDrawer}
            />
          </Box>
          <Box className={classes.logoutSection} onClick={() => logOut()}>
            <Icon className={classes.iconColor}>exit_to_app</Icon>
            {t('common.logOut')}
          </Box>
        </Box>
      </Box>
      <Box className={classes.root}>
        <Drawer
          style={{ pointerEvents: open ? 'all' : 'none' }}
          className={classes.drawer}
          anchor='left'
          open={open}
          onClose={handleDrawer}
          variant={
            device.getScreenSize() < ScreenSizes.md ? undefined : 'persistent'
          }
          classes={{
            paper: classes.drawerPaper,
          }}
          transitionDuration={{ enter: 500, exit: 300 }}
        >
          <Box className={classes.drawerHeader}>
            <Box className={classes.tabs}>
              <Box className={classes.overlay}>
                {navLinks.map((link, index) => (
                  <SideNavLink
                    key={index}
                    label={link.label}
                    path={link.path}
                    exact={link.exact}
                    children={link.children}
                  />
                ))}
                <SideLanguageLink />
              </Box>
            </Box>
          </Box>
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          {/* <ThemeProvider theme={defaultTheme}> */}
          <Switch>
            {routesInfo.map((route, index) => (
              <Route
                key={index}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            ))}
          </Switch>
          {/* </ThemeProvider> */}
        </main>
      </Box>
    </>
  )
}

export default CMS
