import React from 'react'
import { ButtonBase } from '@material-ui/core'
import { useStyles } from './NeroButton.style'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary'
}

const NeroButton = ({
  variant = 'primary',
  className,
  children,
  ...rest
}: ButtonProps) => {
  const classes = useStyles()

  // const classNames = `${classes.root} ${className || ''}`
  return (
    <>
      <ButtonBase className={`${classes.root} ${className}`} {...rest}>
        {children}
      </ButtonBase>
    </>
  )
}

export default NeroButton
