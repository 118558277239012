import React from 'react'
import { Box } from '@material-ui/core'
import Title from '../../../CommonModule/Title'
import Carousel from '../Carousel'
import ProductCard from '../ProductCard'
import { Product } from '../../DataModels/Products'

interface NewArrivalsProps {
  products: Product[]
}
const NewArrivals = ({ products }: NewArrivalsProps) => {
  return (
    <>
      <Box>
        <Title text='Top bán chạy trong tuần'></Title>
        <Carousel
          xlBlockSize={4}
          lgBlockSize={4}
          mdBlockSize={3}
          smBlockSize={2}
          xsBlockSize={1}
          items={products}
          itemTemplate={ProductCard}
          itemPropName='product'
        />
      </Box>
    </>
  )
}

export default NewArrivals
