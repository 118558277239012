import { ActionTypes } from '../actions'
interface AuthType {
  user: Realm.User | null
}
const initialState: AuthType = {
  user: null,
}
const authReducer = (
  state = initialState,
  action: { type: ActionTypes; payload: any }
) => {
  switch (action.type) {
    case ActionTypes.LOGIN: {
      const user: Realm.User = action.payload.user
      return { ...state, user }
    }
    case ActionTypes.LOGOUT: {
      return { ...state, user: null }
    }
    default:
      return state
  }
}
export default authReducer
