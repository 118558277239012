import React from 'react'
import AppConfig from '../../AppConfig'
import * as Realm from 'realm-web'

const AppID = AppConfig.realmAppKey
const userApiKey = AppConfig.realmUserApiKey
const appClient = new Realm.App({ id: AppID })
const apiKeyCredentials = Realm.Credentials.apiKey(userApiKey)

export interface RealmContextProps {
  realmUser: Realm.User | null
  authenticate: (email: string, password: string) => Promise<Realm.User>
  logOut: () => Promise<void>
  resetPassword: (token: string,
    tokenId: string,
    newPassword: string) => Promise<{
    status: 'success' | 'fail'
    message: string
  }>
  sendResetPasswordEmail: (email: string) => Promise<void>
}
const defaultContext = {
  realmUser: appClient.currentUser,
  authenticate: async (email: string, password: string) => ({} as Realm.User),
  logOut: async () => {},
  resetPassword: async (
    token: string,
    tokenId: string,
    newPassword: string
  ) => ({} as {
    status: 'success' | 'fail'
    message: string
  }),
  sendResetPasswordEmail: async (email: string) => {},
}
const RealmContext = React.createContext<RealmContextProps>({} as RealmContextProps)

const initialize = async () => {
  if (!appClient.currentUser) {
    await appClient.logIn(apiKeyCredentials)
  }
}
(async ()=> await initialize())()

interface RealmAppProps {
  children: React.ReactNode
}
const RealmApp = ({ children }: RealmAppProps) => {
  
  const [realmAppClient, setRealmAppClient] = React.useState(appClient)
  const [realmUser, setRealmUser] = React.useState<Realm.User | null>(null)

  /** Authenticate a user. */
  const authenticate = async (email: string, password: string) => {
    const credentials = Realm.Credentials.emailPassword(email, password)
    try {
      const user: Realm.User = await realmAppClient.logIn(credentials)
      setRealmUser(user)
      return user
    } catch (err) {
      // console.log('Authentication failed!', err)
      throw new Error(err)
    }
  }

  /** Send a Reset password email.*/
  const sendResetPasswordEmail = async (email: string) => {
    try {
      // Send a reset password email to user
      const result = await realmAppClient.emailPasswordAuth.sendResetPasswordEmail(
        email
      )
      console.log(result)
    } catch (err) {
      console.error('Failed to send reset password email!', err)
    }
  }

  /** Complete reset a password */
  const resetPassword = async (
    token: string,
    tokenId: string,
    newPassword: string
  ) => {
    let result: {
      status: 'success' | 'fail'
      message: string
    }
    try {
      // reset password
      await realmAppClient.emailPasswordAuth.resetPassword(
        token,
        tokenId,
        newPassword
      )
      result = { status: 'success', message: 'Your password has been reset!' }
    } catch (err) {
      // console.error('Failed to reset password!', err)
      result = { status: 'fail', message: 'Failed to reset password!' }
    }
    return result
  }

  /** Log the current user out. */
  const logOut = async () => {
    if (!realmAppClient.currentUser?.isLoggedIn) return
    // console.log(appClient.currentUser)
    try {
      await realmAppClient.currentUser.logOut()
      // console.log('Logged out!')
    } catch (err) {
      throw new Error(err)
    }
  }

  React.useEffect(()=>{
    (async () => {
      await initialize()
      setRealmUser(appClient.currentUser)
    })()
  },[appClient.currentUser])

  return (
    <RealmContext.Provider
      value={{
        realmUser,
        authenticate,
        logOut,
        resetPassword,
        sendResetPasswordEmail,
      }}
    >
      {children}
    </RealmContext.Provider>
  )
}

export const useRealmApp = () => {
  const realmContext = React.useContext(RealmContext)
  if (realmContext == null) {
    throw new Error('useRealmApp() called outside of a RealmApp?')
  }
  return realmContext
}

export default RealmApp
