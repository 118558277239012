import { createMuiTheme, makeStyles } from '@material-ui/core'

export const defaultTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#FFFFFF',
      main: '#FFB400',
      dark: '#000000',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#F9F9F9',
      main: '#FFB400',
      dark: '#1B1C1E',
      contrastText: '#FFFFFF',
    },
  },
  overrides: {
    MuiTypography: {
      h4: {
        fontSize: 20,
      },
      h5: {
        fontSize: 18,
      },
      h6: {
        fontSize: 16,
      },
    },
    MuiOutlinedInput: {
      input: {
        '&:-webkit-autofill': {
          transition: 'background-color 0s ease-in-out 50000s',
        },
      },
    },
  },
})

export const applyGlobalStyles = makeStyles(
  (theme) => {
    return {
      '@global': {
        body: {
          margin: 0,
          paddingTop: 50,
          display: 'flex',
          justifyContent: 'center',
          fontFamily: 'Roboto, sans-serif',
        },
        '#root': {
          width: '100%',
        },
      },
    }
  },
  { index: 1 }
  // Add an option: { index: 1 } to all makeStyles() invocations, in order to place those sheets after the MUI sheets which have an index of 0 (by default).
)
