import React, { Fragment } from 'react'
import clsx from 'clsx'
import Filter from '../../components/Filter'
import { Box, Grid } from '@material-ui/core'
import ProductCard from '../../components/ProductCard'
import CategoryList from '../../components/CategoryNavigation'
import useProduct from '../../hooks/useProduct'
import useScroll from '../../hooks/useScroll'
import Drawer from '@material-ui/core/Drawer'
import { useStyles } from './ProductsPage.style'
import Icon from '@material-ui/core/Icon'
import useDevice, { ScreenSizes } from '../../../CommonModule/hooks/useDevice'
import '../../../i18n'
import { useTranslation } from 'react-i18next'

const Products = () => {
  const device = useDevice()
  const classes = useStyles()
  const { t } = useTranslation()

  const { content } = useProduct()
  const { scrollToTop } = useScroll()

  const screenSize = device.getScreenSize()
  const [open, setOpen] = React.useState(false)
  React.useEffect(() => {
    if (screenSize) setOpen(screenSize > ScreenSizes.sm)
  }, [screenSize])
  const handleDrawer = () => {
    setOpen(!open)
  }

  const renderProducts = () => {
    return content.filteredProducts.length === 0 ? (
      <Box className={classes.noResultMessage}>{t('common.noResultFound')}</Box>
    ) : (
      <Box display='flex' flexWrap='wrap' width={'100%'}>
        {content.filteredProducts.map((product) => (
          <Grid key={product.id} container item xs={12} sm={6} md={4} lg={3}>
            <ProductCard width={'100%'} product={product}></ProductCard>
          </Grid>
        ))}
      </Box>
    )
  }

  return (
    <Fragment>
      {scrollToTop()}
      <CategoryList></CategoryList>
      <Box className={classes.stickyBar}>
        <Box className={classes.toggleButton} onClick={handleDrawer}>
          {open ? 'Giấu bộ lọc' : 'Xem bộ lọc'}
          <Icon style={{ marginLeft: 10 }}>tune</Icon>
        </Box>
      </Box>
      <Box className={classes.root}>
        <Drawer
          className={classes.drawer}
          anchor='left'
          open={open}
          onClose={handleDrawer}
          variant={screenSize < ScreenSizes.md ? undefined : 'persistent'}
          classes={{
            paper: classes.drawerPaper,
          }}
          transitionDuration={{ enter: 500, exit: 300 }}
        >
          <Box className={classes.drawerHeader}>
            <Filter handleToggle={handleDrawer}></Filter>
          </Box>
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <Box marginBottom={{ xs: 4, md: 6 }} width={'100%'}>
            {renderProducts()}
          </Box>
        </main>
      </Box>
    </Fragment>
  )
}
export default Products
