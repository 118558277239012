const dev = {
  realmAppKey: process.env.REACT_APP_STITCH_KEY || 'devKey',
  realmUserApiKey: process.env.REACT_APP_REALM_API_KEY_USER || '',
  realmDBName: process.env.REACT_APP_DB_NAME || '',
  cloudfrontUrl: process.env.REACT_APP_CLOUDFRONT_URL,
  awsRegion: process.env.REACT_APP_AWS_REGION || '',
  awsIdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID || '',
  awsS3BucketName: process.env.REACT_APP_AWS_BUCKET_NAME || '',
}
const prod = {
  realmAppKey: process.env.REACT_APP_STITCH_KEY || 'prodKey',
  realmUserApiKey: process.env.REACT_APP_REALM_API_KEY_USER || '',
  realmDBName: process.env.REACT_APP_DB_NAME || '',
  cloudfrontUrl: process.env.REACT_APP_CLOUDFRONT_URL,
  awsRegion: process.env.REACT_APP_AWS_REGION || '',
  awsIdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID || '',
  awsS3BucketName: process.env.REACT_APP_AWS_BUCKET_NAME || '',
}
const test = {
  realmAppKey: 'testKey',
  realmUserApiKey: '',
  realmDBName: '',
  cloudfrontUrl: 'testURL',
  awsRegion: '',
  awsIdentityPoolId: '',
  awsS3BucketName: '',
}

const AppConfig = () => {
  switch (process.env.NODE_ENV) {
    case 'test':
      return test
    case 'production':
      return prod
    default:
      return dev
  }
}

export default AppConfig()
