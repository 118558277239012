import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useStyles } from './SocialMedia.style'
import NeroButton from '../../../CommonModule/NeroButton'
import Title from '../../../CommonModule/Title'
import { ImageTile } from '../../hooks/useGallery'

const ImageCard = (props: { tile: ImageTile }) => {
  const { img } = props.tile
  const classes = useStyles()
  return (
    <>
      <div className={classes.tile}>
        <img src={img} alt='' loading='lazy' />
      </div>
    </>
  )
}

interface GalleryProps {
  tiles: ImageTile[]
}
const SocialMedia = ({ tiles = [] }: GalleryProps) => {
  const classes = useStyles()
  const settings = {
    className: classes.customCarousel,
    centerMode: true,
    infinite: true,
    centerPadding: '40px',
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <>
      <Title text='Kho hình ảnh' />
      <div className={classes.wrapper}>
        <Slider {...settings}>
          {tiles.map((tile) => (
            <ImageCard key={tile.img} tile={tile} />
          ))}
        </Slider>
      </div>
    </>
  )
}

export default SocialMedia
