import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {useRealm} from '../../CommonModule/hooks/useStitch'
import { ActionTypes } from '../actions'
import { RootState } from '../reducers'
import { ContactPage, Contact } from '../DataModels/ContactPageContent'

//TODO implement action types for homepage

const useContactPage = () => {
  const stitch = useRealm()
  const dispatch = useDispatch()
  const [content, setContent] = React.useState<ContactPage>()
  const contactPageContent = useSelector(
    (state: RootState) => state.contactPageReducer
  )

  /** Fetch data from Stitch & update to redux store */
  const fetchUIContent = async () => {
    try {
      const data = await (
        await stitch.getCollection('ProductModule', 'ContactPage')
      )?.find() || {}
      dispatch({
        type: ActionTypes.FETCH_CONTACT,
        payload: mapContent(data as Object),
      })
    } catch (error) {
      /**TODO: Handle error here */
      console.log(error)
    }
  }
  /** Map the fetched data into a HomePage typed data */
  const mapContent = (fetchedContent: Object) => {
    return Object.values(fetchedContent).reduce((acc: ContactPage, section) => {
      // console.log(section)
      return {
        ...acc,
        [section.name]: {
          ...section,
        },
      }
    }, {})
  }

  React.useEffect(() => {
    setContent(mapContent(contactPageContent))
  }, [contactPageContent])

  /** NOTE only for creating fake data */
  const upload = () => {
    const data = {
      name: 'contacts',
      contacts: [
        {
          address: 'Address 101',
          email: 'test@gmail.com',
          phoneNumber: '0123456789',
        },
      ] as Contact[],
    }
    stitch.updateCollection(
      'ProductModule',
      'ContactPage',
      { name: data.name },
      data,
      { upsert: true }
    )
  }

  return { fetchUIContent, content, upload }
}

export default useContactPage
