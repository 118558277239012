import { ActionTypes } from '../actions'
import { Product } from '../DataModels/Products'

interface Filters {
  price: number[]
  categoryIds: string[]
  conditions: string[]
  sortBy: SortBy
}
type SortBy = 'name' | 'displayPrice' | 'published_time'

const initialState = {
  products: [] as Product[],
  filters: {
    categoryIds: [],
    price: [0, 80000000],
    conditions: [],
    sortBy: 'published_time',
  } as Filters,
  filteredProducts: [] as Product[],
}

const productReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.UPDATE_FILTERS: {
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      }
    }
    case ActionTypes.FETCH_PRODUCTS: {
      return {
        ...state,
        products: action.payload as Product[],
      }
    }
    case ActionTypes.FILTER_PRODUCTS: {
      return {
        ...state,
        filteredProducts: action.payload as Product[],
      }
    }
    case ActionTypes.ADD_PRODUCT: {
      return {
        ...state,
        products: [
          action.payload as Product,
          ...state.products.filter(
            (prod) => prod.id !== (action.payload as Product).id
          )
        ],
      }
    }
    case ActionTypes.DELETE_PRODUCT: {
      return {
        ...state,
        products: state.products.filter((prod) => prod.id !== action.payload),
      }
    }
    default:
      return state
  }
}

export default productReducer
