import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  (theme) => {
    return {
      arrowDown: {
        /** Box */
        position: 'absolute',
        right: 0,
      },
      bold: {
        fontWeight: 600,
      },
      button: {
        display: 'block',
        margin: 'auto',
        marginTop: 10,
      },
      expandableTextWrapper: {
        display: 'flex',
        borderTop: '1px solid #000',
        position: 'relative',
        alignItems: 'center',
        height: 50,
      },
      productInfoWrapper: {
        width: '100%',
        [theme.breakpoints.up('lg')]: {
          paddingLeft: '10%',
        },
      },
      productName: {
        fontSize: 22,
        lineHeight: '26px',
        margin: 0,
        fontWeight: 'normal',
      },
      productPrice: {
        fontSize: 18,
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        paddingBottom: 5,
        margin: '5px 0 0 0',
        borderBottom: `4px solid ${theme.palette.primary.dark}`,
      },
      productImageWrapper: {
        position: 'relative',
        width: '100%',
        height: '110%',
        background: theme.palette.primary.light,
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
      },
      additionalMargin: {
        marginBottom: '10%',
      },
      listItem: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      listItemIconBackground: {
        background: theme.palette.primary.dark,
      },
      selector: {
        width: '100%',
        marginTop: 20,
      },
    }
  },
  { index: 1 }
)
