import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  (theme) => {
    return {
      categoryList: {
        marginLeft: 25,
      },
      categoryBoxShadow: {
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
        marginBottom: 12,
        padding: '5px 15px 5px 45px',
      },
      categoryItem: {
        position: 'relative',
        margin: '5px 0',
      },
      dropdownIcon: {
        position: 'absolute',
        right: 0,
        top: 2,
      },
      editIcon: {
        position: 'absolute',
        left: -25,
        top: 5,
        fontSize: '1.2em',
      },
      checkBox: {
        padding: 3,
        marginLeft: -5,
      },
      checkBoxTitle: {
        display: 'inline-flex',
        width: '100%',
        textTransform: 'capitalize',
      },
    }
  },
  { name: 'categoryTree', index: 1 }
)
