import { useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {useRealm} from '../../../CommonModule/hooks/useStitch'
import { ActionTypes } from '../../../ProductModule/actions'
import { RootState } from '../../../ProductModule/reducers'
import * as Realm from 'realm-web'

const sessionStorageKey = 'nerodashUser'
const sessionUser = sessionStorage.getItem(sessionStorageKey)
const useAuth = () => {
  const { authenticate, logOut: signOut } = useRealm()
  const authState = useSelector((state: RootState) => state.authReducer)
  const dispatch = useDispatch()
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!sessionUser)

  const readUserFromSession = () => {
    if(!sessionUser) return
    const realmUser = JSON.parse(sessionUser) as Realm.User
    // if(appClient.currentUser?.id !== realmUser.id) return
    setIsAuthenticated(true)
    dispatch({ type: ActionTypes.LOGIN, payload: { user: realmUser } })
  }
  

  const logIn = async (email: string, password: string) => {
    try {
      const user = await authenticate(email, password)
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(user))
      // console.log(sessionStorage.getItem(sessionStorageKey))
      dispatch({ type: ActionTypes.LOGIN, payload: { user } })
      return user
    } catch (err) {
      console.error(err)
    }
  }

  const logOut = async () => {
    try {
      signOut()
      sessionStorage.removeItem(sessionStorageKey)
      dispatch({ type: ActionTypes.LOGOUT })
    } catch (err) {
      console.error(err)
    }
  }

  useLayoutEffect(() => {
    setIsAuthenticated(!!authState.user)
    return () => {
    }
  }, [authState.user])

  useLayoutEffect(()=> {
    readUserFromSession()
  }, [])

  return { user: authState.user, isAuthenticated, logIn, logOut }
}

export default useAuth
