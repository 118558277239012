import { useDispatch, useSelector } from 'react-redux'
import { BSON } from 'realm-web'
import {useRealm} from '../../CommonModule/hooks/useStitch'
import { ActionTypes } from '../actions'
import { Category } from '../DataModels/Categories'
import { RootState } from '../reducers'

const moduleName = 'ProductModule'
const collectionName = 'Categories'
const useCategories = () => {
  const realm = useRealm()
  const dispatch = useDispatch()
  const content = useSelector((state: RootState) => state.categoryReducer)

  /** Fetch data from Stitch & update to redux store */
  const fetchCategories = async () => {
    // const query = {}
    const rawCategories = await(
      await realm.getCollection(moduleName, collectionName)
    )?.find() || []
    // console.log(rawCategories)
    dispatch({
      type: ActionTypes.FETCH_CATEGORIES,
      payload: mapToCategoryList(rawCategories as unknown[]),
    })
  }
  /** Map the fetched data into a list of Category */
  const mapToCategoryList = (fetchedContent: unknown[]) => {
    return fetchedContent.map((item: any) => {
      const cate = new Category(
        item._id,
        item.name,
        item.iconName,
        item.path
      )
      return cate
    })
  }

  /** Find the nearest descendants of a category.*/
  const findNearestDescendants = (cate: Category) => {
    return content.categories.filter((cat: Category) => {
      const paths = cat.path.split('-')
      const lastPath = paths[paths.length - 1]
      // console.log(lastPath[lastPath.length - 1], cate.categoryId)
      return lastPath == cate.id
    })
  }

  /** Upload new category to DB */
  const updateCategory = async (cate: Category) => {
    try {
      // insert new category to DB
      const result = await(
        await realm.getCollection(moduleName, collectionName)
      )?.findOneAndUpdate(
        { _id: new BSON.ObjectId(cate.id) },
        { name: cate.name, iconName: cate.iconName, path: cate.path },
        { upsert: true }
      )
      // dispatch a new action to update redux store
      dispatch({ type: ActionTypes.ADD_CATEGORY, payload: cate })
      // console.log('Response:', result)
      return result
    } catch (error) {
      console.log('An error occurs!: ', error)
    }
  }

  /** Delete a category from DB. */
  const deleteCategory = async (cateId: string) => {
    try {
      const result = await (
        await realm.getCollection(moduleName, collectionName)
      )?.deleteOne({ _id: new BSON.ObjectId(cateId) })

      // update app state in redux store
      dispatch({type: ActionTypes.DELETE_CATEGORY, payload: cateId})
    } catch (err) {
      console.log('An error occurs, failed to delete Category!: ', err)
      return err
    }
  }

  /** Find single Category by ID. */
  const findCategoryById = (id: string) => {
    return content.categories.find(cate => cate.id === id)
  }

  /** Find many categories by list of IDs. */
  const findCategoriesByIds = (ids: string[]) => {
    // find all categories
    let categories: Category[] = []
    ids && ids.map((id) => {
      const cate = content.categories.find((cate) => cate.id === id)
      if (cate !== undefined) categories = [...categories, cate]
    })
    return categories
  }

  const getCategoriesByName = (name: string) => {
    return content.categories
      .filter((cate: Category) => {
        return cate.name.toLowerCase().includes(name.toLowerCase().trim())
      })
      .slice(0, 5)
  }

  return {
    content,
    fetchCategories,
    getCategoriesByName,
    updateCategory,
    deleteCategory,
    findNearestDescendants,
    findCategoriesByIds,
    findCategoryById
  }
}

export default useCategories
