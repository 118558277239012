import React, { useEffect } from 'react'
import L from 'leaflet'
import { Grid, Box, Paper } from '@material-ui/core'
import marker from 'leaflet/dist/images/marker-icon.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'
import 'leaflet/dist/leaflet.css'
import { useStyles } from './ContactAndMap.style'
import useContactPage from '../../hooks/useContactPage'
import { Contact } from '../../DataModels/ContactPageContent'
import Title from '../../../CommonModule/Title'
import Icon from '@material-ui/core/Icon'

const ContactAndMap = () => {
  const classes = useStyles()
  const contacts = useContactPage().content?.contacts?.contacts

  const renderContactBoxes = (contactObjects: Contact[] = []) => {
    return contactObjects.map((contact, index) => {
      return (
        <Paper key={index} className={classes.contactBox}>
          <Box className={classes.contactItem}>
            <Box>
              <Icon style={{ fontSize: 35 }}>home_work</Icon>
            </Box>
            <Box className={classes.mainText} marginLeft={2}>
              {contact.address}
            </Box>
          </Box>
          <a
            style={{ textDecoration: 'none' }}
            href={`mailto:${contact.email}`}
          >
            <Box marginTop={1} className={classes.contactItem}>
              <Box>
                <Icon style={{ fontSize: 35, color: '#000' }}>email</Icon>
              </Box>
              <Box component='span' marginLeft={2} className={classes.subText}>
                {contact.email}
              </Box>
            </Box>
          </a>
          <a
            style={{ textDecoration: 'none' }}
            href={`tel:${contact.phoneNumber}`}
          >
            <Box marginTop={1} className={classes.contactItem}>
              <Box>
                <Icon style={{ fontSize: 35, color: '#000' }}>phone</Icon>
              </Box>

              <Box component='span' marginLeft={2} className={classes.subText}>
                {contact.phoneNumber}
              </Box>
            </Box>
          </a>
        </Paper>
      )
    })
  }

  //Render map
  useEffect(() => {
    //Redefine icon to avoid CORS and enable possibility to use custom icon
    let DefaultIcon = L.icon({
      iconUrl: marker,
      shadowUrl: markerShadow,
    })
    L.Marker.prototype.options.icon = DefaultIcon

    //Set view for map position
    var locations: any = [[10.769525633902813, 106.67382299031802]]
    const map = L.map('map').fitBounds(locations)

    //Add tile layer + contribution mark
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map)

    //Add marker
    for (var i = 0; i < locations.length; i++) {
      L.marker(locations[i]).addTo(map).bindPopup('Rioshop')
    }
  }, [])

  return (
    <Box className={classes.wrapper}>
      <Title text='Thông tin liên hệ' />
      <Grid container>
        <Grid container item xs={12} alignContent='flex-start'>
          {renderContactBoxes(contacts)}
        </Grid>
        <Grid container item xs={12} className={classes.mapWrapper}>
          <div className={classes.embeddedMap} id='map'></div>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ContactAndMap
