import { Box } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import NeroButton from '../../CommonModule/NeroButton'
import { useStyles } from './ImageGalleryEdit.style'
import ImagePreviewPane from '../../CommonModule/ImagePreviewPane/ImagePreviewPane.component'
import useGallery from '../../ProductModule/hooks/useGallery'
import useImagePreview from '../../CommonModule/ImagePreviewPane/useImagePreview'
import { useConfirm } from '../../CommonModule/Confirmation'
import LoadingSpinner from '../../CommonModule/LoadingSpinner'
import '../../i18n'
import { useTranslation } from 'react-i18next'

const ImageGalleryEdit = () => {
  const classes = useStyles()
  const history = useHistory()
  const confirm = useConfirm()
  const { fetchGallery, updateGallery } = useGallery()
  const [loading, setLoading] = React.useState(false)
  const { t } = useTranslation()
  const {
    images,
    imgKeysToDelete,
    setImages,
    removeImage,
    handleChange,
  } = useImagePreview()

  const submitFunction = async (event: React.FormEvent) => {
    event.preventDefault()
    confirm({ title: 'Think again' })
      .then(async () => {
        setLoading(true)
        await updateGallery(images, imgKeysToDelete).catch((err) =>
          console.log(err)
        )
        history.goBack()
      })
      .catch(() => {})
  }

  /** Fetch data on mount. */
  React.useEffect(() => {
    ;(async () => {
      const images = await fetchGallery()
      // console.log(images)
      setImages(images as string[])
    })()
  }, [])

  return loading ? (
    <Box
      height='90vh'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <LoadingSpinner open={true} />
    </Box>
  ) : (
    <Box className={classes.root}>
      <form onSubmit={submitFunction}>
        <Box className={classes.formInnerWrapper}>
          <Box className={classes.sectionLabel}>
            {t('common.galleryImages')}:
          </Box>
          <ImagePreviewPane files={images} handleRemove={removeImage} />
          <label className={classes.customInput}>
            {t('common.selectImage')}
            <input
              className={classes.input}
              type='file'
              id='file'
              aria-label='File browser example'
              onChange={(e) => handleChange(e.target as HTMLInputElement)}
            />
          </label>
        </Box>
        <NeroButton className={classes.submitButton} type='submit'>
          {t('common.submit')}
        </NeroButton>
      </form>
    </Box>
  )
}

export default ImageGalleryEdit
