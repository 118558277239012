import React, {useState} from 'react'
import { Box } from '@material-ui/core'
import useCategories from '../../ProductModule/hooks/useCategories'
import { useStyles } from './CategoryList.style'
import NeroButton from '../../CommonModule/NeroButton/NeroButton.component'
import CategoryTree from '../../CommonModule/CategoryTree/CategoryTree.component'
import { Category } from '../../ProductModule/DataModels/Categories'
import LoadingSpinner from '../../CommonModule/LoadingSpinner'
import { Link } from 'react-router-dom'
import '../../i18n'
import { useTranslation } from 'react-i18next'

const CategoryList = (props: any) => {
  const { content, deleteCategory } = useCategories()
  const classes = useStyles()
  const [selectedValues, setSelectedValues] = React.useState<string[]>([])
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const handleDelete = async() => {
    setLoading(prev => !prev)
    for(const value of selectedValues) {
      await deleteCategory(value)
    }
    setLoading(prev => !prev)
    setSelectedValues([])
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValues((prevSelectedValues) => {
      if (prevSelectedValues.includes(event.target.value)) {
        return [
          ...prevSelectedValues.filter((item) => item !== event.target.value),
        ]
      } else {
        return [...prevSelectedValues, event.target.value]
      }
    })
  }

  return loading ? (
    <Box
      height='90vh'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <LoadingSpinner open={true} />
    </Box>
  ) : (
    <>
      <Box className={classes.addDeleteButtonWrapper}>
        <Link style={{ textDecoration: 'none' }} to={`/cms/categories/new`}>
          <NeroButton>{t('common.addCategory')}</NeroButton>
        </Link>
        {selectedValues.length > 0 && (
          <NeroButton onClick={handleDelete}>{t('common.delete')}</NeroButton>
        )}
      </Box>
      <Box className={classes.categoryListWrapper}>
        {content.categories
          .filter((c) => c.path === '')
          .map((cate: Category, index) => (
            <CategoryTree
              key={index}
              category={cate}
              level={0}
              handleChange={handleChange}
              selectedValues={selectedValues}
              editable={true}
            />
          ))}
      </Box>
    </>
  )
}
export default CategoryList
