import React from 'react'
import { Box, Icon, Collapse, Checkbox } from '@material-ui/core'
import { Category } from '../../ProductModule/DataModels/Categories'
import useCategories from '../../ProductModule/hooks/useCategories'
import { useStyles } from './CategoryTree.style'
import { Link } from 'react-router-dom'

interface TreeProps {
  category: Category
  level: number
  selectedValues: string[]
  handleChange: any
  editable?: boolean
  openCollapse?: boolean
  boxShadow?: boolean
}
/** TODO - still in experimental phase */
/** A Component to recursively render a category and its descendants. */

const CategoryTree = ({
  category,
  level,
  selectedValues,
  handleChange,
  editable = false,
  openCollapse = false,
  boxShadow = true,
}: TreeProps) => {
  const classes = useStyles()
  const { findNearestDescendants, findCategoryById } = useCategories()
  const nearestChildren = findNearestDescendants(category)
  const [open, setOpen] = React.useState(openCollapse)

  const handleDropdown = () => {
    setOpen(!open)
  }

  /** Manipulate the open state if the selectedValue is in the scope. */
  React.useLayoutEffect(() => {
    const selectedCates = selectedValues?.map((value) =>
      findCategoryById(value)
    )
    if (
      selectedCates !== undefined &&
      nearestChildren.some((cate) =>
        selectedCates.some((selectedCate) =>
          selectedCate?.fullPath.includes(cate.id)
        )
      )
    ) {
      // console.log(category.name, category.fullPath, selectedValue)
      setOpen(true)
    }
  }, [selectedValues])

  return (
    <>
      <Box
        className={
          level > 0
            ? classes.categoryList
            : boxShadow
            ? classes.categoryBoxShadow
            : ''
        }
        key={category.id}
      >
        <Box className={classes.categoryItem}>
          <Box display='flex' alignItems='center'>
            {editable && (
              <Link
                style={{ textDecoration: 'none', color: '#000' }}
                to={`/cms/categories/${category.id}`}
              >
                <Icon className={classes.editIcon}>edit</Icon>
              </Link>
            )}
            <Checkbox
              className={classes.checkBox}
              checked={selectedValues?.includes(category.id)}
              onChange={handleChange}
              value={category.id}
              inputProps={{ 'aria-label': category.id }}
            />
            <Box className={classes.checkBoxTitle} onClick={handleDropdown}>
              {`${category.name}`}
              {nearestChildren.length !== 0 && (
                <Icon className={classes.dropdownIcon}>
                  {open ? 'keyboard_arrow_up_icon' : 'keyboard_arrow_down_icon'}
                </Icon>
              )}
            </Box>
          </Box>

          {nearestChildren.length !== 0 && (
            <>
              {nearestChildren.map((child, index) => (
                <Collapse key={index} in={open} timeout='auto' unmountOnExit>
                  <CategoryTree
                    key={index}
                    category={child}
                    level={level + 1}
                    selectedValues={selectedValues}
                    handleChange={handleChange}
                    editable={editable}
                    openCollapse={openCollapse}
                  />
                </Collapse>
              ))}
            </>
          )}
        </Box>
      </Box>
    </>
  )
}

export default CategoryTree
