import React, { useState } from 'react'
import { NavLink, useLocation, Link } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Icon,
} from '@material-ui/core'

import SearchBar, { SearchIcon } from '../Search'
import { useStyles } from './Navigation.style'
import Hamburger from '../../../CommonModule/Hamburger'
import Arrow from '../../../CommonModule/Arrow'
import { NavItemProps } from '../../DataModels/HomePageContent'
import useHomePage from '../../hooks/useHomePage'
import useDevice, { ScreenSizes } from '../../../CommonModule/hooks/useDevice'

/** SECTION - Main Components */
const NavLogo = () => {
  return (
    <>
      <Box flexGrow={1} alignItems='center'>
        <Link style={{ textDecoration: 'none', color: '#fff' }} to={`/`}>
          <IconButton color='inherit' aria-label='menu'>
            <Typography variant='h4' color='inherit'>
              <span style={{ color: '#FFB400' }}>Rio</span>Shop
            </Typography>
          </IconButton>
        </Link>
      </Box>
    </>
  )
}

const NavItem = (props: any) => {
  const { children, path, triggerDropdown, closeDropdown } = props
  const classes = useStyles()
  const [expand, setExpand] = React.useState(false)

  return (
    <>
      <Box display='flex' flexDirection='row' alignItems='center'>
        <NavLink
          exact
          className={classes.navItem}
          isActive={(match, location) => {
            // exception check for route '/'
            if (path === '/') return !!match

            // stays active if the current route is the subroute of the NavLink
            return location.pathname.includes(path)
          }}
          activeClassName={classes.navItemActive}
          to={path}
          onMouseEnter={triggerDropdown}
          onMouseLeave={closeDropdown}
        >
          {children.title}
        </NavLink>
        {children.subItems && (
          <Arrow
            className={classes.arrow}
            direction={expand ? 'up' : 'down'}
            handleClick={() => {
              setExpand((state) => !state)
            }}
          />
        )}
      </Box>
      {children.subItems && (
        <Box className={classes.categories} maxHeight={expand ? '100%' : 0}>
          {children.subItems.map((item: any, index: number) => (
            <NavLink
              key={`${item.title}${index}`}
              className={classes.navItem}
              activeClassName={classes.navItemActive}
              to={`${path}/${item.path}`}
            >
              <Icon className={classes.categoryIcon}>{item.iconName}</Icon>{' '}
              {item.title}
            </NavLink>
          ))}
        </Box>
      )}
    </>
  )
}

/** Dropdown menu for sub items of NavItem in desktop mode */
const DropDownMenu: React.FC<{
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  navItem?: NavItemProps
  show: boolean
}> = ({ onMouseEnter, onMouseLeave, navItem, show }) => {
  const classes = useStyles()
  return (
    <>
      <Box
        className={classes.dropdownMenu}
        style={{
          maxHeight: show ? 55 : 0,
          transition: 'all 0.5s ease',
        }}
      ></Box>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={classes.curtain}
        style={{
          maxHeight: show ? 65 : 0,
          transition: 'all 0.5s ease',
        }}
      >
        <Box className={classes.innerCurtain} marginX='auto'>
          {navItem?.subItems?.map((item, index) => {
            return (
              <NavLink
                style={{ textTransform: 'capitalize' }}
                key={`${item.title}${index}`}
                className={classes.navItem}
                activeClassName={classes.navItemActive}
                to={`${item.path}`}
              >
                <Icon className={classes.categoryIcon}>{item.iconName}</Icon>{' '}
                {item.title}
              </NavLink>
            )
          })}
        </Box>
      </div>
    </>
  )
}

const Navigation = () => {
  const { content } = useHomePage()
  const classes = useStyles()
  const [shouldRenderSearch, setShouldRenderSearch] = useState(false)
  const [shouldRenderDropdown, setShouldRenderDropdown] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [dropdownItem, setDropdownItem] = useState<NavItemProps>()
  const device = useDevice()
  const location = useLocation()

  React.useEffect(() => {
    /** Close menu in small screen when navigate */
    closeMenu()
    closeSearch()
  }, [location.pathname])

  /** changes the display state of the full screen menu */
  const closeMenu = () => {
    if (openMenu !== false) setOpenMenu(false)
  }

  /** Close the search bar */
  const closeSearch = () => {
    if (shouldRenderSearch) setShouldRenderSearch(false)
  }

  /** changes the display state of the search input */
  const toggleSearch = () => {
    setShouldRenderSearch((state) => !state)
  }

  /** changes the display state of the dropdown menu */
  const openDropdown = (navItem: NavItemProps) => {
    if (navItem.subItems) {
      setDropdownItem(navItem)
      setShouldRenderDropdown(true)
    }
  }
  const closeDropdown = () => {
    setShouldRenderDropdown(false)
  }

  /** Render UI */
  return (
    <>
      <AppBar position='absolute' className={classes.custom}>
        <Toolbar variant='dense' className={classes.root}>
          {!content?.navBar?.navItems ? null : (
            <Box
              className={classes.wrapper}
              style={{
                transitionDelay: openMenu ? '0s' : '0.5s',
              }}
            >
              <Box className={classes.logo}>
                <Hamburger
                  isOpen={openMenu}
                  onClick={() => setOpenMenu((state) => !state)}
                />
                <NavLogo />
              </Box>
              <div
                className={classes.navigationWrapper}
                style={
                  device.getScreenSize() < ScreenSizes.md
                    ? {
                        // NOTE temporary transition
                        maxHeight: openMenu ? 'calc(100vh - 55px)' : '0px',
                      }
                    : undefined
                }
              >
                <Box
                  className={`${classes.searchBox} ${
                    shouldRenderSearch ? classes.expanded : classes.hidden
                  }`}
                >
                  <SearchBar
                    isExpanded={shouldRenderSearch}
                    handleToggle={toggleSearch}
                  />
                </Box>
                <Box
                  className={`${classes.navigation} 
                ${!shouldRenderSearch ? classes.expanded : classes.hidden}`}
                >
                  {content?.navBar?.navItems.map((nav, ind) => (
                    <NavItem
                      triggerDropdown={() => openDropdown(nav)}
                      closeDropdown={() => closeDropdown()}
                      key={ind}
                      index={ind}
                      path={nav.path}
                    >
                      {nav}
                    </NavItem>
                  ))}
                  <Box alignSelf='center' className={classes.navItem}>
                    <SearchIcon
                      className={classes.icon}
                      color='red'
                      onClick={() => toggleSearch()}
                    />
                  </Box>
                </Box>
              </div>
            </Box>
          )}
        </Toolbar>
        {/* <DropDownMenu
          onMouseEnter={() => setShouldRenderDropdown(true)}
          onMouseLeave={() => closeDropdown()}
          navItem={dropdownItem}
          show={shouldRenderDropdown}
        /> */}
      </AppBar>
    </>
  )
}

export default Navigation
