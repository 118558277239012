import React from 'react'
import { Box, Grid } from '@material-ui/core'
import useHomePage from '../../hooks/useHomePage'
import { useStyles } from './Banner.style'
import { BannerProps } from '../../DataModels/HomePageContent'

const BannerContent = ({
  header,
  text,
  subText,
  imgUrl,
  mobileImgUrl,
  colorTheme,
}: BannerProps) => {
  const classes = useStyles()

  return (
    <Grid
      container
      className={colorTheme === 'light' ? classes.lightTheme : ''}
    >
      <Grid className={classes.bannerTextWrapper} container item xs={12}>
        <Box className={classes.bannerText}>
          <h2 className={classes.header}>{header}</h2>
          <Box className={classes.text}>{text}</Box>
          <Box className={classes.subText}>{subText}</Box>
          <Box className={classes.buttonLabel}>Mua ngay</Box>
        </Box>
      </Grid>
      <Grid className={classes.bannerImageWrapper} container item xs={12}>
        <picture className={classes.repositionImage}>
          <source media='(min-width: 600px)' srcSet={`${imgUrl}`} />
          <source srcSet={`${mobileImgUrl}`} />
          <img className={classes.bannerImage} alt={header} />
        </picture>
      </Grid>
    </Grid>
  )
}
const Banner = () => {
  const banner = useHomePage().content?.banner
  const classes = useStyles()
  return !banner ? null : (
    <>
      <Box className={classes.root}>
        {banner.banners.map((banner, index) => (
          <BannerContent {...banner} key={index} />
        ))}
      </Box>
    </>
  )
}

export default Banner
