import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  (theme) => {
    return {
      addDeleteButtonWrapper: {
        marginTop: 20,
        textAlign: 'center',
        '& > button': {
          margin: 5,
        },
        [theme.breakpoints.up('sm')]: {
          textAlign: 'left',
          '& > button': {
            margin: '0 0 0 10px',
          },
        },
      },
      categoryListWrapper: {
        height: 'auto',
        marginTop: 20,
        padding: 10,
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
        [theme.breakpoints.up('sm')]: {
          padding: '30px 40px',
        },
        [theme.breakpoints.up('lg')]: {
          padding: '40px 70px',
        },
      },
    }
  },
  { name: 'categoryList', index: 1 }
)
