import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      overflowWrap: 'anywhere',
      height: 'auto',
      backgroundColor: theme.palette.common.white,
    },
    contactBox: {
      /** Box */
      display: 'block',
      width: '100%',
      padding: '25px',
      margin: '0px 0px 20px 0px',
      borderRadius: 5,
      /** Visual */
      boxShadow: '0 0 10px 0 rgba(0,0,0,0.15)',
      /** Typography */
      color: 'black',
    },
    contactItem: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      transition: 'all ease-out 0.4s',
      '&:hover': {
        marginLeft: 15,
      },
    },
    mainText: {
      color: '#000',
      /** Typography */
      fontSize: '12px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '16px',
      },
    },
    subText: {
      color: '#000',
      /** Typography */
      fontSize: '12px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '16px',
      },
    },
    mapWrapper: {
      padding: 15,
      boxShadow: '0 0 10px 0 rgba(0,0,0,0.15)',
      borderRadius: 5,
      // [theme.breakpoints.up('lg')]: {
      //   margin: '0px 0px 0px 25px',
      // },
    },
    embeddedMap: {
      /** Box */
      width: '100%',
      paddingTop: '50%',
      borderRadius: 5,
    },
    liveChatBox: {
      background: theme.palette.primary.dark,
    },
  }),
  { index: 1 }
)
