import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  (theme) => ({
    filterWrapper: {
      width: '100%',
      position: 'relative',
    },
    filterRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    filterGroupTitle: {
      textTransform: 'uppercase',
      fontSize: 16,
      fontWeight: 600,
      padding: '0 0 10px 0',
      marginTop: 10,
    },
    checkBox: {
      padding: 3,
      marginLeft: 5,
    },
    checkBoxLabel: {
      fontSize: 14,
      textTransform: 'capitalize',
    },
    wrapperCloseButton: {
      position: 'fixed',
      boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
      top: 0,
      left: 0,
      height: 50,
      width: '100%',
      background: theme.palette.primary.light,
      zIndex: 1,
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    closeButton: {
      display: 'block',
      position: 'absolute',
      top: 12,
      right: 20,
      fontSize: 30,
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    wrapperButtonMobile: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
      bottom: 0,
      left: 0,
      height: 100,
      width: '100%',
      background: theme.palette.primary.light,
      zIndex: 1,
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    buttonMobile: {
      position: 'relative',
      width: 200,
      padding: '12px 20px',
      background: theme.palette.primary.dark,
      color: theme.palette.primary.light,
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  }),
  { index: 1 }
)
