import { BSON } from "realm-web"


export class Category {
  private _id: BSON.ObjectId
  name: string
  iconName: string
  path: string

  /** Return Id as a string. */
  public get id(): string {
    return this._id.toString()
  }

  public get level(): number {
    return this.path.split('-').length
  }

  public get fullPath(): string {
    return `${this.path !== '' ? `${this.path}-` : ''}${this.id}`
  }

  public get parentId(): any {
    const splitPaths = this.path.split('-')
    // console.log(splitPaths[splitPaths.length-1])
    return this.path !== '' ? splitPaths[splitPaths.length-1] : ''
  }

  constructor(id = new BSON.ObjectId(), name = '', iconName = '', path = '') {
    this._id = id
    this.name = name
    this.iconName = iconName
    this.path = path
  }
}
