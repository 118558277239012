import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Parallax as ParallaxType } from '../DataModels/HomePageContent'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: 400,
      [theme.breakpoints.up('md')]: {
        height: 600,
      },
    },
    parallax: (props: any) => ({
      /** Box-model */
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100%',

      /** Typography */
      color: 'white',

      /** Visual */
      backgroundImage: `url(${props.imgUrl})`,

      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      [theme.breakpoints.up('md')]: {
        backgroundAttachment: 'fixed',
      },
    }),
    content: {
      fontSize: 24,
      textTransform: 'uppercase',
      boxShadow: '0 0 15px 0 rgba(255,255,255)',
      padding: '10px 25px',
      textAlign: 'center',
      background: 'rgba(0,0,0,0.5)',
    },
  }),
  { name: 'Parallax', index: 1 }
)

interface ParallaxProps {
  content: ParallaxType
}
const Parallax = ({
  content = { id: '', imgUrl: '', title: '' },
}: ParallaxProps) => {
  const classes = useStyles(content)
  return (
    <>
      <div className={classes.root}>
        <div className={classes.parallax}>
          <h3 className={classes.content}>{content.title}</h3>
        </div>
      </div>
    </>
  )
}

export default Parallax
