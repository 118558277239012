import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20,
        padding: 40,
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
      },
      mainTitle: {
        fontSize: 20,
        fontWeight: 500,
        textTransform: 'uppercase',
        paddingLeft: 10,
        marginBottom: 30,
      },
      box: {
        margin: 10,
        padding: 20,
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
        justifyContent: 'center',
      },
    }
  },
  { name: 'dashboard', index: 1 }
)
