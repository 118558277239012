import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {useRealm} from '../../CommonModule/hooks/useStitch'
import { ActionTypes } from '../actions'
import { AboutPage } from '../DataModels/AboutPageContent'
import { RootState } from '../reducers'

const useAboutPage = () => {
  const stitch = useRealm()
  const dispatch = useDispatch()
  const [content, setContent] = React.useState<AboutPage>()
  const aboutPageContent = useSelector(
    (state: RootState) => state.aboutPageReducer
  )
  /** Fetch data from Stitch & update to redux store */
  const fetchUIContent = async () => {
    try {
      const data = await (await stitch.getCollection('ProductModule', 'AboutPage'))?.find() || {}
      dispatch({ type: ActionTypes.FETCH_ABOUTPAGE, payload: mapContent(data as Object) })
    } catch (error) {
      /**TODO: Handle error here */
      console.log(error)
    }
  }

  /** Map the fetched data into a HomePage typed data */
  const mapContent = (fetchedContent: Object) => {
    return Object.values(fetchedContent).reduce((acc: AboutPage, section) => {
      // console.log(section)
      return {
        ...acc,
        [section.name]: {
          ...section,
        },
      }
    }, {})
  }

  React.useEffect(() => {
    setContent(mapContent(aboutPageContent))
  }, [aboutPageContent])

  return {fetchUIContent, content}
}

export default useAboutPage
