import React from 'react'
import { useStyles } from './LoginPage.style'
import { Box, Button, Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { Message } from '../../../CommonModule/Message'
import LoadingSpinner from '../../../CommonModule/LoadingSpinner'
import useAuth from '../../../CMSModule/Auth/hooks/useAuth'
import '../../../i18n'
import { useTranslation } from 'react-i18next'

interface FormInputs {
  email: string
  password: string
}
const defaultValues: FormInputs = {
  email: '',
  password: '',
}

const LoginPage = () => {
  const classes = useStyles()
  const { logIn } = useAuth()
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const { from } = (location.state as { from: { pathname: string } }) || {
    from: { pathname: '/cms' },
  }

  const { register, handleSubmit, reset } = useForm<FormInputs>({
    defaultValues,
  })
  const [message, setMessage] = React.useState<string | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)

  const handleLogin = async (data: FormInputs) => {
    // authenticate user, set error on fail
    setIsLoading(true)
    const user = await logIn(data.email, data.password)
    if (!user) {
      const mess = 'Your email or password is incorrect. Please try again!'
      setMessage(mess)
      setIsLoading(false)
      return
    }
    // navigate to CMS dashboard on success
    history.replace(from)
  }

  /** Handle close event of the message stack */
  const handleCloseMessage = () => {
    // reset message text
    setMessage(null)
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.loginBox}>
        <Box className={classes.loginText}>
          Nero<span style={{ color: '#FFB400' }}>Core</span>
        </Box>
        <Box paddingTop={8} width={'100%'}>
          <form onSubmit={handleSubmit(handleLogin)}>
            <TextField
              className={classes.textField}
              id='username'
              label={t('common.userName')}
              variant='outlined'
              size='small'
              name='email'
              inputRef={register}
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              InputProps={{
                className: classes.inputBorder,
              }}
            />
            <TextField
              className={classes.textField}
              id='password'
              name='password'
              type='password'
              inputRef={register}
              label={t('common.password')}
              variant='outlined'
              size='small'
              style={{ marginTop: 15 }}
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              InputProps={{
                className: classes.inputBorder,
              }}
            />
            <Button type='submit' className={classes.button}>
              {t('common.logIn')}
            </Button>
            {/* <Box className={classes.subText}>Or login with</Box> */}
          </form>
        </Box>
      </Box>

      {/* This section is for some eventually used Utility-components. */}
      <LoadingSpinner open={isLoading} />
      <Message
        open={!!message}
        message={message || undefined}
        onClose={handleCloseMessage}
      />
    </Box>
  )
}

export default LoginPage
