export enum ActionTypes {
  /** Homepage */
  FETCH_HOMEPAGE = 'FETCH_HOMEPAGE',

  /** Contact Page */
  FETCH_CONTACT = 'FETCH_CONTACT',

  /** About Page */
  FETCH_ABOUTPAGE = 'FETCH_ABOUTPAGE',

  /** Products */
  FETCH_PRODUCTS = 'FETCH_PRODUCTS',
  FIND_PRODUCTS = 'FIND_PRODUCTS',
  FILTER_PRODUCTS = 'FILTER_PRODUCTS',
  ADD_PRODUCT = 'ADD_PRODUCT',
  DELETE_PRODUCT = 'DELETE_PRODUCT',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
  
  /** Categories */
  FETCH_CATEGORIES = 'FETCH_CATEGORIES',
  ADD_CATEGORIES = 'ADD_CATEGORIES',
  ADD_CATEGORY = 'ADD_CATEGORY',
  DELETE_CATEGORY = 'DELETE_CATEGORY',

  /** Auth */
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',

  /** Error */
  ADD_ERROR = 'ADD_ERROR',
  REMOVE_ERROR = 'REMOVE_ERROR',
}
