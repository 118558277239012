import React from 'react'
import useHomePage from './useHomePage'
import useProduct from './useProduct'
import useContactPage from './useContactPage'
// import useAboutPage from './useAboutPage'
import useCategories from './useCategories'
import { useRealm } from '../../CommonModule/hooks/useStitch'

const useApp = () => {
  const homePage = useHomePage()
  const products = useProduct()
  const contactPage = useContactPage()
  // const aboutPage = useAboutPage()
  const categories = useCategories()
  const { realmDB } = useRealm()

  /** Initialize the whole app UI content */
  const initializeApp = async () => {
    // console.log('reached here')
    homePage.fetchUIContent()
    contactPage.fetchUIContent()
    products.fetchProducts()
    categories.fetchCategories()
  }

  React.useEffect(() => {
    (async () => await initializeApp())()
  }, [realmDB])
}
export default useApp
