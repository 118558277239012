import React from 'react'
import { Box } from '@material-ui/core'
import { useStyles } from './CategoryNavigation.style'
import Icon from '@material-ui/core/Icon'
import useProduct from '../../hooks/useProduct'
import useCategories from '../../hooks/useCategories'
import { Category } from '../../DataModels/Categories'
import { toCapitalFirstLetter } from '../../../CommonModule/hooks/useUtilities'
import { useHistory } from 'react-router'
import LoadingSpinner from '../../../CommonModule/LoadingSpinner'

interface CategoryDropdownProps {
  category: Category
  handleSelect: Function
}
const CategoryDropdown = ({
  category,
  handleSelect,
}: CategoryDropdownProps) => {
  const classes = useStyles()
  const { findNearestDescendants } = useCategories()
  return (
    <>
      <li
        className={classes.subGroup}
        onClickCapture={() => handleSelect(category.id)}
      >
        {category.name}
        {findNearestDescendants(category).length !== 0 && (
          <ul className={classes.subGroupCategories}>
            {findNearestDescendants(category).map((child, index) => (
              <CategoryDropdown
                key={index}
                handleSelect={handleSelect}
                category={child}
              />
            ))}
          </ul>
        )}
      </li>
    </>
  )
}
const CategoryNavigation = () => {
  const classes = useStyles()
  const { content } = useCategories()
  const { findNearestDescendants, findCategoryById } = useCategories()
  const { updateFilters } = useProduct()
  const history = useHistory()
  // console.log('result: ', result)

  const clearSelectedFilter = () => {
    updateFilters('categoryIds', [])
    updateFilters('conditions', [])
  }

  /** Handle when user click out the suggested category */
  const handleCateSelect = (cateId: string) => {
    const cate = findCategoryById(cateId)
    clearSelectedFilter()

    // add selected category to the filter list
    updateFilters('categoryIds', [cateId])
    // redirect to the product page
    history.push({
      pathname: '/products',
    })
  }

  const handleOldProductCateSelect = () => {
    clearSelectedFilter()
    updateFilters('conditions', ['99%', 'cũ'])
    history.push({
      pathname: '/products',
    })
  }

  // console.log(content)
  if (content.categories.length !== 0) {
    return (
      <Box className={classes.wrapper}>
        {content.categories
          .filter((c) => c.path === '')
          .map((cate: Category, index) => (
            <Box key={index} className={classes.categoryGroupWrapper}>
              <Box
                key={index}
                className={classes.categoryWrapper}
                onClick={() => handleCateSelect(cate.id)}
              >
                <Icon className={classes.icon}>{cate.iconName}</Icon>
                <Box className={classes.label}>
                  {toCapitalFirstLetter(cate.name)}
                </Box>
              </Box>
              <ul className={classes.dropdown}>
                {findNearestDescendants(cate).length !== 0 && (
                  <>
                    {findNearestDescendants(cate).map((child, index) => (
                      <CategoryDropdown
                        key={index}
                        handleSelect={handleCateSelect}
                        category={child}
                      />
                    ))}
                  </>
                )}
              </ul>
            </Box>
          ))}
        {/* <Box className={classes.categoryGroupWrapper}>
          <Box
            className={classes.categoryWrapper}
            onClick={() => handleOldProductCateSelect()}
          >
            <Icon className={classes.icon}>devices_other_icon</Icon>
            <Box className={classes.label}>Kho máy cũ</Box>
          </Box>
        </Box> */}
      </Box>
    )
  } else {
    return (
      <Box
        height='90vh'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <LoadingSpinner open={true} />
      </Box>
    )
  }
}

export default CategoryNavigation
