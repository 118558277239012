import { useEffect } from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'

export enum ScreenSizes {
  xs = 1,
  sm = 2,
  md = 3,
  lg = 4,
  xl = 5,
  // xs = 'xs',
  // sm = 'sm',
  // md = 'md',
  // lg = 'lg',
}

const useDevice = () => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.only('xs'))
  const sm = useMediaQuery(theme.breakpoints.only('sm'))
  const md = useMediaQuery(theme.breakpoints.only('md'))
  const lg = useMediaQuery(theme.breakpoints.only('lg'))
  const getScreenSize = () => {
    return xs
      ? ScreenSizes.xs
      : sm
      ? ScreenSizes.sm
      : md
      ? ScreenSizes.md
      : lg
      ? ScreenSizes.lg
      : ScreenSizes.xl
  }

  useEffect(() => {
    return () => {}
  }, [getScreenSize()])

  return { getScreenSize }
}

export default useDevice
