import React from 'react'

const useImagePreview = () => {
  const [images, setImages] = React.useState<(File | string)[]>([])
  const [imgKeysToDelete, setImgKeysToDelete] = React.useState<string[]>([])
  
  /** Handle when user remove a file or image. */
  const removeImage = (item: string | File) => {
    // add item to the filesToDelete if it has been in the DB
    if (typeof item === 'string')
      setImgKeysToDelete((prev) => [
        ...prev.filter((key) => key !== item),
        item,
      ])

    // update the state
    setImages((prevImages) =>
      prevImages.filter((image) => {
        if (typeof item === 'string') return !(image as string).includes(item)
        else return (image as File).name !== item.name
      })
    )
  }

  /** Update the selected files */
  const handleChange = (target: EventTarget & HTMLInputElement) => {
    setImages((prevList) =>
      prevList.concat(Array.from(target.files as FileList))
    )
  }

  return { images, imgKeysToDelete, setImages, handleChange, removeImage }
}

export default useImagePreview
