import React, { Fragment } from 'react'
import {
  Switch,
  Route,
  useLocation,
  RouteProps,
  Redirect,
} from 'react-router-dom'

import Products from './ProductModule/pages/ProductsPage/ProductsPage.component'
import Home from './ProductModule/pages/HomePage'
import Navigation from './ProductModule/components/Navigation'
import ProductDetailsPage from './ProductModule/pages/ProductDetailsPage'
import Footer from './ProductModule/components/Footer'
import { ThemeProvider, Box } from '@material-ui/core'
import { defaultTheme, applyGlobalStyles } from './App.style'
import useApp from './ProductModule/hooks/useApp'
import AboutPage from './ProductModule/pages/AboutPage'
import CMS from './CMSModule/CMS'
import LoginPage from './ProductModule/pages/LoginPage/LoginPage.component'
import useAuth from './CMSModule/Auth/hooks/useAuth'
import { ErrorMessage } from './CommonModule/Message'
import { ConfirmProvider } from './CommonModule/Confirmation'

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>
}
const PrivateRoute = React.memo(
  ({ component: Component, ...rest }: PrivateRouteProps) => {
    const { isAuthenticated } = useAuth()
    // console.log(isAuthenticated)

    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated ? (
            <Component />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )
        }
      />
    )
  }
)

const App = () => {
  applyGlobalStyles()
  useApp()
  const location = useLocation()
  const routesInfo = [
    { path: '/', component: Home, exact: true },
    { path: '/login', component: LoginPage, exact: true },
    { path: '/products', component: Products, exact: true },
    { path: '/about', component: AboutPage, exact: true },
    { path: '/cms', component: CMS, exact: false, isPrivate: true },
    { path: '/products/:id', component: ProductDetailsPage, exact: true },
  ]

  /** Check if the current route needs Navbar & Footer. */
  const isWithNavbarAndFooter = React.useMemo(() => {
    // routes that do not need navBar & Footer
    const exceptionRoutes = ['/login', '/resetPassword', '/cms']
    return !exceptionRoutes.some((route) => location.pathname.match(route))
  }, [location.pathname])

  return (
    <Fragment>
      <ThemeProvider theme={defaultTheme}>
        <ConfirmProvider>
          <Switch>
            <>
              <Box style={{ background: '#fff' }} maxWidth={1920} margin='auto'>
                {isWithNavbarAndFooter && <Navigation />}
                {routesInfo.map((route, index) =>
                  route.isPrivate === true ? (
                    <PrivateRoute
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      component={route.component}
                    />
                  ) : (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      component={route.component}
                    />
                  )
                )}
                {isWithNavbarAndFooter && <Footer />}
              </Box>
            </>
          </Switch>
          <ErrorMessage />
        </ConfirmProvider>
      </ThemeProvider>
    </Fragment>
  )
}

export default App
