import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px 0',
      background: '#000',
      boxShadow: '0 0 30px 0 rgba(255,255,255,0.15)',
    },
    categoryGroupWrapper: {
      transition: 'all ease-out 0.3s',
      '&:hover': {
        '& $dropdown': {
          visibility: 'visible',
          opacity: 1,
        },
      },
      [theme.breakpoints.up('md')]: {
        position: 'relative',
      },
    },
    dropdown: {
      display: 'none',
      position: 'absolute',
      left: 0,
      width: 'auto',
      color: theme.palette.primary.dark,
      listStyle: 'none',
      zIndex: 100,
      margin: 0,
      padding: 0,
      textTransform: 'capitalize',
      fontSize: 16,
      background: theme.palette.primary.light,
      transition: 'all ease-out 0.3s',
      visibility: 'hidden',
      opacity: 0,
      boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    subGroup: {
      position: 'relative',
      cursor: 'pointer',
      padding: '12px 20px',
      boxSizing: 'border-box',
      transition: 'all ease-out 0.4s',
      whiteSpace: 'nowrap',
      '&:hover': {
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
        '& > $subGroupCategories': {
          opacity: 1,
          visibility: 'visible',
        },
      },
    },
    subGroupName: {
      padding: 0,
    },
    subGroupCategories: {
      position: 'absolute',
      top: 0,
      left: '100%',
      width: 'auto',
      margin: 0,
      padding: 0,
      background: theme.palette.primary.light,
      opacity: 0,
      visibility: 'hidden',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      listStyle: 'none',
      boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
    },
    categoryWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      padding: '5px 20px',
      [theme.breakpoints.up('sm')]: {
        padding: '10px 30px',
      },
      '&:hover': {
        '& $icon, & $label': {
          color: `${theme.palette.primary.main}`,
        },
      },
    },
    icon: {
      fontSize: 40,
      color: theme.palette.primary.light,
      transition: 'all ease-out 0.2s',
    },
    label: {
      marginTop: 10,
      fontSize: 14,
      color: '#fff',
      transition: 'all ease-out 0.2s',
    },
  }),
  { index: 1 }
)
