import React, { useState } from 'react'
import { TextField, Box, InputAdornment, Grow } from '@material-ui/core'
import { useStyles } from './Search.style'
import useProduct from '../../hooks/useProduct'
import SearchResult from './SearchResult.component'
import { Product } from '../../DataModels/Products'
import { Category } from '../../DataModels/Categories'
import useCategories from '../../hooks/useCategories'

interface SearchProps {
  isExpanded: boolean
  handleToggle: () => void
}

/** SECTION - Main Components */
interface SvgIconProps {
  onClick?: () => void
  color?: string
  className?: string
}
export const SearchIcon: React.FC<SvgIconProps> = ({ children, ...props }) => {
  return (
    <svg
      {...props}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.73005 12.1195C4.79403 10.8618 4.24242 9.31681 4.24242 7.64708C4.24242 3.42381 7.77014 0 12.1216 0C16.473 0 20 3.42381 20 7.64708C20 11.8697 16.473 15.2942 12.1216 15.2942C10.5877 15.2942 9.15616 14.8686 7.94543 14.1326L3.01514 19.4865C2.33272 20.15 1.23515 20.1712 0.527879 19.5459L0.498175 19.5171L0.46848 19.4865C-0.175762 18.8018 -0.155152 17.7365 0.527879 17.0736L5.73005 12.1195ZM6.06121 7.64708C6.06121 4.39854 8.77484 1.76471 12.1218 1.76471C15.4688 1.76471 18.1824 4.39854 18.1824 7.64708C18.1824 10.8956 15.4688 13.5295 12.1218 13.5295C8.77484 13.5295 6.06121 10.8956 6.06121 7.64708Z'
        fill='white'
      />
    </svg>
  )
}
export const CloseIcon: React.FC<SvgIconProps> = ({ children, ...props }) => {
  return (
    <svg
      {...props}
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15 0.837923L14.1621 0L7.5 6.66798L0.837923 0L0 0.837923L6.66798 7.5L0 14.1621L0.837923 15L7.5 8.33202L14.1621 15L15 14.1621L8.33202 7.5L15 0.837923Z'
        fill='white'
      />
    </svg>
  )
}

const SearchBar: React.FC<SearchProps> = ({ handleToggle }) => {
  const [input, setInput] = useState('')
  const [shouldRenderResult, setShouldRenderResult] = useState(false)
  const [searchResult, setSearchResult] = useState<{
    products: Product[]
    categories: Category[]
  }>({ products: [], categories: [] })
  const classes = useStyles()
  const { getProductsByName } = useProduct()
  const { getCategoriesByName } = useCategories()

  const handleClickAway = () => {
    /** clear input when click away */
    // setInput('')
    handleToggle()
  }

  const handleInputChange = (value: string) => {
    setInput(value)

    // do not search unless the input is filled
    if (value) {
      setSearchResult({
        products: getProductsByName(value),
        categories: getCategoriesByName(value),
      })
      setShouldRenderResult(true)
    } else setShouldRenderResult(false)
  }

  // console.log('search comp', isExpanded)
  return (
    <>
      {/* <ClickAwayListener onClickAway={handleClickAway}> */}
      <Box className={classes.wrapper}>
        <TextField
          onChange={(e) => handleInputChange(e.target.value)}
          value={input}
          onFocus={(e) => handleInputChange(e.target.value)}
          onBlur={() => setShouldRenderResult(false)}
          className={classes.text}
          id='input-with-icon-grid'
          placeholder='Tìm kiếm...'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <CloseIcon
                  // fontSize='default'
                  className={classes.clickable}
                  onClick={handleClickAway}
                ></CloseIcon>
              </InputAdornment>
            ),
          }}
        ></TextField>

        <Grow in={shouldRenderResult} timeout={400}>
          <div className={classes.result}>
            <SearchResult result={searchResult} />
          </div>
        </Grow>
      </Box>
      {/* </ClickAwayListener> */}
    </>
  )
}

export default SearchBar
