import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRealm } from '../../CommonModule/hooks/useStitch'
import { ActionTypes } from '../actions'
import { HomePage } from '../DataModels/HomePageContent'
import { RootState } from '../reducers'

//TODO implement action types for homepage
const defaultContent: HomePage = {
  navBar: { id: '', navItems: [] },
  banner: {
    id: '',
    banners: [],
  },
  campaignSection: {
    id: '',
    campaigns: [],
  },
  parallax: { id: '', title: '', imgUrl: '' },
  gallerySection: {
    imageTiles: [],
  },
  footer: {
    id: '',
    subscription: {
      header: '',
    },
    sections: [],
    copyRight: '',
  },
}
const useHomePage = () => {
  const stitch = useRealm()
  const dispatch = useDispatch()
  const [content, setContent] = React.useState<HomePage>(defaultContent)
  const homePageContent = useSelector(
    (state: RootState) => state.homePageReducer
  )

  // React.useEffect(()=>{
  //   const timeout = setTimeout(async ()=>{
  //     // await fetchUIContent()
  //     console.log(stitch)
  //   }, 500)
  //   return ()=>{
  //     clearTimeout(timeout)
  //   }
  // }, [stitch])
  /** Fetch data from Stitch & update to redux store */
  const fetchUIContent = async () => {
    // console.log(stitch)
    try {
      const data =
        (await (
          await stitch.getCollection('ProductModule', 'HomePage')
        )?.find()) || {}
      dispatch({ type: ActionTypes.FETCH_HOMEPAGE, payload: data })
    } catch (error) {
      /**TODO: Handle error here */
      console.log(error)
    }
  }
  /** Map the fetched data into a HomePage typed data */
  const mapContent = (fetchedContent: Object) => {
    return Object.values(fetchedContent).reduce((acc: HomePage, section) => {
      return {
        ...acc,
        [section.name]: {
          ...section,
        },
      }
    }, {})
  }

  React.useEffect(() => {
    setContent(mapContent(homePageContent))
  }, [homePageContent])

  return { fetchUIContent, content }
}

export default useHomePage
