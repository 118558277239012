import React, { Fragment } from 'react'
import {
  Box,
  Divider,
  Checkbox,
  Slider,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  FormControl,
  Radio,
} from '@material-ui/core'
import CategoryTree from '../../../CommonModule/CategoryTree/CategoryTree.component'
import { useStyles } from './Filter.style'
import useProduct from '../../hooks/useProduct'
import useCategories from '../../../ProductModule/hooks/useCategories'
import { Category } from '../../DataModels/Categories'
import Icon from '@material-ui/core/Icon'
import { makeStyles } from '@material-ui/core/styles'
import useDebounce from '../../../CommonModule/hooks/useDebounce'

const sliderClasses = makeStyles((theme) => ({
  root: {
    color: '#ffb400',
    height: 8,
    width: '99%',
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))

interface FilterProps {
  handleToggle: Function
}
enum ActionTarget {
  category = 0,
  condition = 1,
  sort = 2,
  price = 3,
}
const Filter = ({ handleToggle }: FilterProps) => {
  const classes = useStyles()
  const { updateFilters, content: productsState } = useProduct()
  const { content: categoriesState } = useCategories()
  const { categoryIds, conditions, price, sortBy } = productsState.filters
  const [selectedCates, setSelectedCates] = React.useState<string[]>(
    categoryIds
  )
  const [selectedConditions, setSelectedConditions] = React.useState<string[]>(
    conditions
  )
  const [selectedPrice, setSelectedPrice] = React.useState<number[]>(price)
  const debouncePrice = useDebounce(selectedPrice, 300)
  const [selectedSortBy, setSelectedSortBy] = React.useState<string>(sortBy)

  const sortItems = [
    { keyName: 'name', displayName: 'Tên sản phẩm' },
    { keyName: 'displayPrice', displayName: 'Giá tiền' },
    { keyName: 'published_time', displayName: 'Thời gian đăng' },
  ]

  /** Handle each select action and update the corresponding selected filters. */
  const handleSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    target: ActionTarget
  ) => {
    const selectedValue = event.target.value
    switch (target) {
      case ActionTarget.category: {
        setSelectedCates((prevState) => {
          if (prevState.includes(selectedValue)) {
            return [...prevState.filter((item) => item !== selectedValue)]
          } else {
            return [...prevState, selectedValue]
          }
        })
        break
      }
      case ActionTarget.condition: {
        setSelectedConditions((prevState) => {
          if (prevState.includes(selectedValue)) {
            return [...prevState.filter((item) => item !== selectedValue)]
          } else {
            return [...prevState, selectedValue]
          }
        })
        break
      }
      case ActionTarget.sort: {
        setSelectedSortBy((event.target as HTMLInputElement).value)
        break
      }
    }
  }

  /** Synchronize global filters with the local. */
  React.useEffect(() => {
    if (selectedCates !== categoryIds) setSelectedCates(categoryIds)
    if (selectedConditions !== conditions) setSelectedConditions(conditions)
    if (debouncePrice !== price) setSelectedPrice(price)
    if (selectedSortBy !== sortBy) setSelectedSortBy(sortBy)
  }, [productsState.filters])

  /** Update selected filters to the global filters. */
  React.useEffect(() => {
    updateFilters('categoryIds', selectedCates)
  }, [selectedCates])
  React.useEffect(() => {
    updateFilters('conditions', selectedConditions)
  }, [selectedConditions])
  React.useEffect(() => {
    updateFilters('price', debouncePrice)
  }, [debouncePrice])
  React.useEffect(() => {
    updateFilters('sortBy', selectedSortBy)
  }, [selectedSortBy])

  return (
    <Fragment>
      <Box marginBottom={{ xs: 4, md: 6 }} className={classes.filterWrapper}>
        <Box className={classes.wrapperCloseButton}>
          <Icon className={classes.closeButton} onClick={() => handleToggle()}>
            highlight_off
          </Icon>
        </Box>
        <Box className={classes.wrapperButtonMobile}>
          <Box className={classes.buttonMobile} onClick={() => handleToggle()}>
            Xác nhận
          </Box>
        </Box>
        <Box>
          <Box className={classes.filterGroupTitle}>Mục lục:</Box>
          {categoriesState.categories
            .filter((c) => c.path === '')
            .map((cate: Category, index) => (
              <CategoryTree
                key={index}
                category={cate}
                level={0}
                selectedValues={selectedCates}
                handleChange={(event: any) =>
                  handleSelect(event, ActionTarget.category)
                }
                boxShadow={false}
              />
            ))}
        </Box>
        <Divider />
        <Box className={classes.filterGroupTitle}>Tình trạng:</Box>
        <FormGroup row>
          {[
            'mới 100% chưa kích hoạt',
            'mới 100% đã kích hoạt',
            'như mới 99.9%',
            'cũ 99%',
            'cũ 98%',
            'cũ 97%',
          ].map((cond) => (
            <FormControlLabel
              key={cond}
              classes={{ label: classes.checkBoxLabel }}
              control={
                <Checkbox
                  onChange={(event: any) =>
                    handleSelect(event, ActionTarget.condition)
                  }
                  className={classes.checkBox}
                  value={cond}
                  checked={selectedConditions.includes(cond)}
                />
              }
              label={cond}
            />
          ))}
        </FormGroup>
        <Divider />
        <Box className={classes.filterGroupTitle}>
          {`Giá tiền:
          ${selectedPrice[0].toLocaleString()} đến ${selectedPrice[1].toLocaleString()}`}
          :
        </Box>
        <Slider
          classes={sliderClasses()}
          valueLabelDisplay='off'
          max={80000000}
          step={10000}
          onChange={(event, value) => setSelectedPrice(value as number[])}
          value={selectedPrice}
        />
        <Divider />
        <Box className={classes.filterGroupTitle}>Sắp xếp theo:</Box>
        <FormControl component='fieldset'>
          <RadioGroup
            value={selectedSortBy}
            onChange={(event) => handleSelect(event, ActionTarget.sort)}
          >
            {sortItems.map((item) => (
              <FormControlLabel
                key={item.keyName}
                value={item.keyName}
                control={<Radio />}
                label={item.displayName}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </Fragment>
  )
}

export default Filter
