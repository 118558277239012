import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        height: 'auto',
        width: '100%',
      },
      formInnerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20,
        padding: '0 20px 20px 20px',
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
      },
      sectionLabel: {
        marginTop: 15,
        fontWeight: 600,
        textTransform: 'uppercase',
      },
      textField: {
        marginTop: 15,
      },
      input: {
        display: 'none',
      },
      customInput: {
        padding: '5px 20px',
        border: `2px solid ${theme.palette.primary.dark}`,
        width: 130,
        margin: '15px auto 0px auto',
        borderRadius: '5px',
        color: theme.palette.getContrastText(theme.palette.primary.light),
        textAlign: 'center',
        transition: 'all ease-out 0.3s',
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.primary.main,
          border: `2px solid ${theme.palette.primary.main}`,
          color: theme.palette.getContrastText(theme.palette.primary.dark),
        },
        [theme.breakpoints.up('sm')]: {
          margin: '15px 0px 0px 0px',
        },
      },
      submitButton: {
        position: 'relative',
        top: 20,
        left: '50%',
        transform: 'translateX(-50%)',
        marginBottom: 30,
      },
      warningText: {
        fontSize: 14,
        marginTop: 5,
        color: theme.palette.primary.main,
      },
    }
  },
  { name: 'categoryEdit', index: 1 }
)
