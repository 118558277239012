
/** Transform the first letter to capital. */
export const toCapitalFirstLetter = (string: string) => {
  return string ? `${string[0].toUpperCase()}${string.slice(1)}` : string
}

/** Convert file to base64. */
export const toBase64 = async (
  file: File,
  callback: (result: string | ArrayBuffer | null) => void
) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    callback(reader.result)
  }
  reader.onerror = (error) => {
    console.log `An error occurs, failed to convert!: ${error}`
  }
}

export default {toCapitalFirstLetter, toBase64}
