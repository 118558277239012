import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      textAlign: 'center',
      cursor: 'pointer',
      zIndex: 2,
    },
  }),
  { name: 'arrow' }
)

interface Arrow {
  name: string
  element: JSX.Element
}

const directionArrow: Arrow[] = [
  {
    name: 'left',
    element: (
      <svg
        width='20'
        height='30'
        viewBox='0 0 20 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0)'>
          <path
            d='M18.4211 28.5L8.9474 15L18.4211 1.5'
            stroke='#FFB400'
            strokeWidth='2'
            strokeMiterlimit='10'
          />
          <path
            d='M11.5789 28.5L2.10523 15L11.5789 1.5'
            stroke='#FFB400'
            strokeWidth='2'
            strokeMiterlimit='10'
          />
        </g>
        <defs>
          <clipPath id='clip0'>
            <rect
              x='20'
              width='30'
              height='20'
              transform='rotate(90 20 0)'
              fill='white'
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    name: 'right',
    element: (
      <svg
        width='20'
        height='30'
        viewBox='0 0 20 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0)'>
          <path
            d='M1.57886 1.5L11.0525 15L1.57886 28.5'
            stroke='#FFB400'
            strokeWidth='2'
            strokeMiterlimit='10'
          />
          <path
            d='M8.42114 1.5L17.8948 15L8.42114 28.5'
            stroke='#FFB400'
            strokeWidth='2'
            strokeMiterlimit='10'
          />
        </g>
        <defs>
          <clipPath id='clip0'>
            <rect
              y='30'
              width='30'
              height='20'
              transform='rotate(-90 0 30)'
              fill='white'
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    name: 'up',
    element: (
      <svg
        width='30'
        height='20'
        viewBox='0 0 30 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
          <path
            d='M1.5 18.4211L15 8.94737L28.5 18.4211'
            stroke='#FFB400'
            strokeWidth='2'
            strokeMiterlimit='10'
          />
          <path
            d='M1.5 11.5789L15 2.10527L28.5 11.5789'
            stroke='#FFB400'
            strokeWidth='2'
            strokeMiterlimit='10'
          />
      </svg>
    ),
  },
  {
    name: 'down',
    element: (
      <svg
        width='30'
        height='20'
        viewBox='0 0 30 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M28.5 1.57895L15 11.0526L1.5 1.57895'
          stroke='#FFB400'
          strokeWidth='2'
          strokeMiterlimit='10'
        />
        <path
          d='M28.5 8.42105L15 17.8947L1.5 8.42105'
          stroke='#FFB400'
          strokeWidth='2'
          strokeMiterlimit='10'
        />
      </svg>
    ),
  },
]

interface ArrowProps {
  direction: 'left' | 'up' | 'right' | 'down'
  handleClick?: () => void,
  className?: string,
}
const Arrow: React.FC<ArrowProps> = ({ direction, handleClick, className }) => {
  const classes = useStyles()
  const getArrow = () => {
    return directionArrow.find((arrow) => arrow.name === direction)?.element
  }

  return (
    <div
      className={`${classes.root} ${className}`}
      onClick={() => (handleClick ? handleClick() : undefined)}
    >
      {getArrow()}
    </div>
  )
}

export default Arrow
