import { Box, MenuItem, TextField, Icon } from '@material-ui/core'
import React from 'react'
import { Controller, FieldError, useFieldArray, useForm } from 'react-hook-form'
import { useParams, useHistory } from 'react-router-dom'
import { BSON } from 'realm-web'
import { useConfirm } from '../../CommonModule/Confirmation'
import { useImagePreview } from '../../CommonModule/ImagePreviewPane'
import ImagePreviewPane from '../../CommonModule/ImagePreviewPane/ImagePreviewPane.component'
import NeroButton from '../../CommonModule/NeroButton'
import { Product, Version } from '../../ProductModule/DataModels/Products'
import useProduct from '../../ProductModule/hooks/useProduct'
import CategorySelector from '../CategorySelector/CategorySelector.component'
import { useStyles } from './ProductEdit.style'
import LoadingSpinner from '../../CommonModule/LoadingSpinner'
import '../../i18n'
import { useTranslation } from 'react-i18next'

interface VersionInputProps {
  version: Version
  name: string
  index: number
  handleRemove: Function
  register: any
}
/** Render a group of inputs for each version of a product. */
const VersionInput = ({
  version,
  name,
  index,
  handleRemove,
  register,
}: VersionInputProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Box className={classes.versionWrapper}>
      <TextField
        className={classes.versionItem}
        label={t('product.storageSize')}
        type='text'
        defaultValue={version.storageSize}
        name={`${name}[${index}].storageSize`}
        inputRef={register()}
      />
      <TextField
        className={classes.versionItem}
        label={t('product.color')}
        type='text'
        defaultValue={version.color}
        name={`${name}[${index}].color`}
        inputRef={register()}
      />
      <TextField
        className={classes.versionItem}
        label={t('product.price')}
        type='number'
        defaultValue={version.price?.value}
        name={`${name}[${index}].price.value`}
        inputRef={register()}
      />
      <TextField
        className={classes.versionItem}
        label={t('product.currency')}
        type='text'
        defaultValue={version.price?.currency}
        name={`${name}[${index}].price.currency`}
        inputRef={register()}
      />
      <Box className={classes.removeButton}>
        <Icon onClick={() => handleRemove()}>highlight_off</Icon>
      </Box>
    </Box>
  )
}

interface FormInputs {
  name: string
  description: string
  condition: string
  versions: Version[]
  category: string
  imgFiles: (File | string)[]
}

const defaultValue: FormInputs = {
  name: '',
  description: '',
  condition: 'mới',
  versions: [
    {
      storageSize: '64gb',
      color: 'white',
      price: { value: 0, currency: 'VNĐ' },
    },
  ],
  category: '',
  imgFiles: [],
}
const ProductEdit = () => {
  const classes = useStyles()
  const confirm = useConfirm()
  const { id: routeId } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(false)
  const {
    images: files,
    imgKeysToDelete,
    setImages: setFiles,
    removeImage,
    // handleChange,
  } = useImagePreview()

  const {
    updateProduct,
    // deleteProduct,
    findProductById,
    content,
  } = useProduct()
  const history = useHistory()

  const {
    handleSubmit,
    setValue,
    errors,
    // trigger,
    // formState,
    clearErrors,
    setError,
    register,
    control,
    watch,
  } = useForm<FormInputs>({
    shouldUnregister: false,
    defaultValues: defaultValue,
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'versions',
  })

  /** Synchronize product data to the form.  */
  const syncProduct = (product: Product) => {
    setValue('name', product.name)
    setValue('description', product.description)
    setValue('condition', product.condition)
    setValue('versions', product.versions)
    setValue('category', product.category)
    setFiles(product.imgUrls)
  }

  /** Update the selected files */
  const handleFileChange = (target: EventTarget & HTMLInputElement) => {
    setFiles((prevList) =>
      prevList.concat(Array.from(target.files as FileList))
    )
  }

  /** Handle the selected value from Category selector. */
  const handleCateSelected = (cateId: string) => {
    // sync the category in the form
    setValue('category', cateId, { shouldValidate: true })
  }

  /** Handle delete product action. */
  // const handleDelete = async () => {
  //   if (!routeId) return

  //   await deleteProduct(routeId)
  //TODO: navigate back to the list
  // }

  const isCustomInputsValid = () => {
    const versionMiss = watch('versions').length === 0
    const imgMiss = watch('imgFiles').length === 0

    if (versionMiss)
      setError('versions', {
        type: 'validate',
        message: t('message.pleaseCreateAtLeastAVersion'),
      })
    if (imgMiss)
      setError('imgFiles', {
        type: 'validate',
        message: t('message.pleaseSelectAtLeastAnImage'),
      })
    return !(versionMiss || imgMiss)
  }

  const submitFunction = async (data: FormInputs) => {
    if (!isCustomInputsValid()) return

    // console.log('Form data:', data)
    const product = new Product(
      routeId === 'new' ? undefined : new BSON.ObjectId(routeId),
      data.name,
      data.description,
      undefined,
      undefined,
      data.category,
      data.versions,
      data.condition
    )
    confirm()
      .then(async () => {
        setLoading(true)
        await updateProduct(product, data.imgFiles, imgKeysToDelete)
        history.goBack()
      })
      .catch(() => {})
  }

  /** Register custom inputs. */
  React.useEffect(() => {
    register({ name: 'imgFiles', type: 'custom' }, { required: true })
    register(
      { name: 'category', type: 'custom' },
      { required: { value: true, message: t('message.pleaseSelectACategory') } }
    )
  }, [])

  /** Synchronize files to form state. */
  React.useEffect(() => {
    setValue('imgFiles', files, { shouldValidate: true })
    // console.log(files, imgKeysToDelete)
  }, [files])

  /** Initialize inputs data from the route id. */
  React.useEffect(() => {
    // console.log(routeId)
    if (routeId !== 'new') {
      const prod = findProductById(routeId)
      prod && syncProduct(prod)
      // console.log(prod)
    }
  }, [routeId, content])

  return loading ? (
    <Box
      height='90vh'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <LoadingSpinner open={true} />
    </Box>
  ) : (
    <Box className={classes.root}>
      <form onSubmit={handleSubmit(submitFunction)}>
        <Box className={classes.formInnerWrapper}>
          <Box className={classes.sectionLabel}>{t('product.info')}:</Box>
          <TextField
            className={classes.textField}
            name='name'
            type='text'
            label={
              errors.name?.type === 'required'
                ? errors.name.message
                : `${t('common.name')}`
            }
            inputRef={register({
              required: {
                value: true,
                message: `${t('message.doNotLeaveNameEmpty')}`,
              },
            })}
            InputLabelProps={{ shrink: watch('name') !== '' || undefined }}
            variant='outlined'
            size='small'
          />
          <TextField
            className={classes.textField}
            multiline
            rows={3}
            name='description'
            type='text'
            label={
              errors.description?.type === 'required'
                ? errors.description.message
                : `${t('product.description')}`
            }
            inputRef={register({
              required: {
                value: true,
                message: `${t('message.pleaseFillInDescription')}`,
              },
            })}
            InputLabelProps={{
              shrink: watch('description') !== '' || undefined,
            }}
            variant='outlined'
            size='small'
          />
          <Controller
            name='condition'
            control={control}
            as={
              <TextField
                className={classes.textField}
                select
                name='condition'
                defaultValue='mới 100% chưa kích hoạt'
                label={t('product.condition')}
                inputRef={register}
                variant='outlined'
                size='small'
              >
                {[
                  'mới 100% chưa kích hoạt',
                  'mới 100% đã kích hoạt',
                  'như mới 99.9%',
                  'cũ 99%',
                  'cũ 98%',
                  'cũ 97%',
                ].map((cond, ind) => (
                  <MenuItem key={ind} value={cond}>
                    {cond}
                  </MenuItem>
                ))}
              </TextField>
            }
          />
          <Box className={classes.sectionLabel}>{t('product.version')}:</Box>
          {fields.map((version: any, index) => (
            <VersionInput
              key={index}
              version={version}
              index={index}
              handleRemove={() => remove(index)}
              name='versions'
              register={register}
            />
          ))}
          <Box marginTop={2}>
            <Icon
              fontSize='large'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                append({ storageSize: '64gb', color: 'white', price: 0 })
                clearErrors('versions')
              }}
            >
              add_circle_outline_icon
            </Icon>
          </Box>
          <Box className={classes.warningText}>
            {errors.versions &&
              ((errors.versions as unknown) as FieldError).message}
          </Box>
          <Box className={classes.sectionLabel}>{t('category.category')}:</Box>
          <CategorySelector
            name='category'
            handleChange={handleCateSelected}
            selectedValue={[watch('category')]}
          />
          <Box className={classes.warningText}>
            {errors.category && errors.category.message}
          </Box>
          <Box className={classes.sectionLabel}>{t('product.image')}:</Box>
          <ImagePreviewPane files={files} handleRemove={removeImage} />
          <label className={classes.customInput}>
            {t('common.selectImage')}
            <input
              className={classes.input}
              type='file'
              id='file'
              aria-label='File browser example'
              onChange={(e) => handleFileChange(e.target as HTMLInputElement)}
            />
          </label>
          <Box className={classes.warningText}>
            {errors.imgFiles &&
              ((errors.imgFiles as unknown) as FieldError).message}
          </Box>
        </Box>
        <NeroButton className={`${classes.submitButton}`} type='submit'>
          {t('common.submit')}
        </NeroButton>
      </form>
    </Box>
  )
}

export default ProductEdit
