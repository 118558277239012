import AWS from 'aws-sdk'
import AppConfig from '../../AppConfig'

const region = AppConfig.awsRegion
const credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: AppConfig.awsIdentityPoolId,
})

AWS.config.update({
  credentials: credentials,
  region: region,
})

// console.log('Region ne:', region, 'Cred ne:', credentials)
const s3Client = new AWS.S3()

export const useAWS = () => {
  /** Upload file to S3 bucket. */
  const uploadFile = async (file: File, key: string) => {
    const params = {
      Bucket: AppConfig.awsS3BucketName,
      Key: key,
      Body: file,
      ContentType: file.type,
    }
    try {
      const result = await s3Client.upload(params).promise()
      // console.log(result)
      return result
    } catch (err) {
      console.log('Error occurs, failed to upload!', err)
      return err
    }
  }

  /** Upload multiple files to S3 bucket. */
  const uploadFiles = async (files: File[], keyPrefix: string) => {
    let urls: string[] = []
    for(const file of files) {
      const key = `${keyPrefix}-${file.name}`
      const result = await uploadFile(file, key)
      urls.push(result.Key)
    }
    // console.log(urls)
    return urls
  }
  

  /** Delete multiple files from S3 bucket.*/
  const deleteFiles = async (keys: string[]) => {
    if(keys.length === 0) return
    const deleteParam = {
      Bucket: AppConfig.awsS3BucketName,
      Delete: {
        Objects: keys.map((key) => ({
          Key: key,
        })),
      },
    }
    // console.log(deleteParam)
    try {
      const result = await s3Client.deleteObjects(deleteParam).promise()
      // console.log(result)
      return result
    } catch (err) {
      console.log('Error occurs, failed to delete!', err)
      throw new Error(err)
    }
  }

  /** Handle Upload/Delete images to AWS S3. */
  const handleUploadImages = async (keyPrefix: string, images: (File | string)[], imageKeysToDelete: string[]) => {
    // get all img keys that already uploaded.
    const getNameRegEx = '[^/]*$'
    const imgKeys = images
      .filter((img) => typeof img === 'string')
      .map((img) => {
        const key = (img as string).match(getNameRegEx)?.[0] || img
        return key as string
      })
    // console.log(filesToUpload, imgKeys)
    let error
    let imagesUrls: string[] = imgKeys
    // upload only files picked from browser.
    const filesToUpload = images.filter(
      (img) => typeof img !== 'string'
    ) as File[]
    
    try {
      await deleteFiles(imageKeysToDelete)
      const imgKeys = await uploadFiles(filesToUpload, keyPrefix)
      imagesUrls = [...imagesUrls, ...imgKeys]
    } catch (err) {
      console.log('An error occurs, failed to upload or delete images!: ', err)
      error = err
    }
    return { imagesUrls, error }
  }

  return { handleUploadImages, deleteFiles  }
}
export default useAWS
