import { makeStyles, Theme, createStyles } from '@material-ui/core'

/** SECTION - JSS classes */
export const useStyles = makeStyles(
  (theme: Theme) => {
    const navItemWidth = 300
    const screenPreset = theme.breakpoints.values.md
    return createStyles({
      custom: {
        boxShadow: 'none',
      },
      root: {
        /** Box */
        overflow: 'hidden',
        /** Visual */
        background: theme.palette.primary.dark,
        [theme.breakpoints.up(screenPreset)]: {
          height: 50,
        },
        /** Misc */
        '&.MuiToolbar-gutters': {
          padding: 0,
        },
      },
      wrapper: {
        // flexDirection: 'column',
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        transition: 'all 0.25s ease',
        maxWidth: 1920,
        margin: 'auto',
        [theme.breakpoints.up(screenPreset)]: {
          padding: '0 50px',
        },
        [theme.breakpoints.down(screenPreset)]: {
          flexDirection: 'column',
        },
      },
      logo: {
        /** Box */
        display: 'flex',
        flexGrow: 1,
        width: '95%',
        minHeight: 50,
        alignItems: 'center',
        [theme.breakpoints.down(screenPreset)]: {
          textAlign: 'center',
        },
      },
      searchBox: {
        /** Box */
        width: '90%',
        marginBlockEnd: '20px',
        marginLeft: 'auto',
        marginRight: 'auto',
        /** Typo */
        textAlign: 'center',
        /** Misc */
        transition: `all 0.5s ease`,
        [theme.breakpoints.up(screenPreset)]: {
          /** Position */
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          /** Box */
          width: '100%',
          height: '100%',
          '& $hidden': {},
          '& $expanded': {},
        },
      },
      hidden: {
        [theme.breakpoints.up(screenPreset)]: {
          /** Misc */
          opacity: 0,
          zIndex: -99,
        },
      },
      expanded: {
        [theme.breakpoints.up(screenPreset)]: {
          /** Misc */
          opacity: 1,
          zIndex: 99,
        },
      },
      navigationWrapper: {
        width: 'auto',
        height: 'auto',
        maxWidth: '-webkit-fill-available',
        transition: 'max-height 0.5s ease',
      },
      navigation: {
        display: 'flex',
        flexDirection: 'row',
        height: '-webkit-fill-available',
        transition: 'all 0.5s ease',
        [theme.breakpoints.down(screenPreset)]: {
          /** Box */
          height: '100vh',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          /** Typo */
          /** Misc */
        },
      },
      navItem: {
        /** Box */
        width: 'max-content',
        // height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 20px',
        /** Typo */
        fontWeight: 300,
        color: '#fff',
        textDecoration: 'none',
        /** Misc */
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.primary.main,
          '& path': {
            fill: theme.palette.primary.main,
          },
        },
        '& svg': {
          marginRight: '10px',
        },
        /** Media query */
        [theme.breakpoints.up(screenPreset)]: {
          height: 50,
        },
        [theme.breakpoints.down(screenPreset)]: {
          width: navItemWidth,
          textAlign: 'center',
          marginBlockStart: '12.5px',
          marginBlockEnd: '12.5px',
        },
      },
      arrow: {
        position: 'absolute',
        right: `calc((100vw - ${navItemWidth}px)/2)`,
        [theme.breakpoints.up(screenPreset)]: {
          display: 'none',
        },
      },
      categories: {
        /** Box */
        width: '100%',
        // height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textTransform: 'capitalize',
        overflow: 'hidden',
        /** Visual*/
        /** Misc */
        transition: 'all 0.5s ease',
        '&:before, &:after': {
          content: '""',
          width: 300,
          borderBlockStart: '2px solid white',
        },
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      categoryIcon: {
        marginRight: 8,
      },
      icon: {
        display: 'block',
        [theme.breakpoints.down(screenPreset)]: {
          display: 'none',
        },
      },
      text: {
        /** Typo */
        color: theme.palette.getContrastText(theme.palette.background.default),
      },
      navItemActive: {
        /** Typo */
        color: '#FFB400',
      },
      dropdownMenu: {
        /** Position */
        position: 'fixed',
        top: 50,
        /** Box */
        display: 'flex',
        width: '100%',
        height: 50,
        /** Visual */
        boxShadow: 'none',
        backgroundColor: 'black',
        opacity: 0.5,
        overflow: 'hidden',
        [theme.breakpoints.down(screenPreset)]: {
          display: 'none',
        },
      },
      innerCurtain: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        boxShadow: '1px 1px 20px 0 rgba(0,0,0,0.1)',
      },
      curtain: {
        /** Position */
        position: 'fixed',
        top: 50,
        /** Box */
        width: '100%',
        height: 50,
        /** Misc */
        overflow: 'hidden',
        [theme.breakpoints.down(screenPreset)]: {
          '& *': {
            display: 'none',
          },
        },
      },
    })
  },
  { name: 'NavComponent', index: 1 }
)
