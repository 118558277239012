import { BSON } from 'realm-web'
import AppConfig from '../../AppConfig'
import { Category } from './Categories'

type Price = {
  value: number
  currency: string
}
export interface Version {
  storageSize?: string
  color?: string
  price: Price
}
export class Product {
  private _id: BSON.ObjectId
  name: string
  description: string
  private _imgUrls: string[]
  published_time: Date
  category: string
  versions: Version[]
  condition: string
  numberInStock: number

  public get id(): string {
    return this._id.toString()
  }

  /** Return the lowest price of all versions as price for display. */
  public get displayPrice(): any {
    return (
      Math.min(
        ...this.versions.flatMap((ver) =>
          ver.price !== undefined ? ver.price?.value : 0
        )
      ) || 0
    )
  }
  /** Return the currency of the lowest price version. */
  public get displayCurrency(): any {
    const minPriceVersion = this.versions.reduce((acc, ver) =>
      ver.price?.value < acc.price?.value ? ver : acc
    )
    // console.log(this.versions, minPriceVersion)
    return minPriceVersion.price.currency || '$'
  }

  public get imgUrls(): string[] {
    return this._imgUrls.map((url) => `${AppConfig.cloudfrontUrl}/${url}`)
  }
  public get imgKeys(): string[] {
    return this._imgUrls
  }
  public set imgUrls(urls: string[]) {
    this._imgUrls = urls
  }

  constructor(
    id = new BSON.ObjectId(),
    name = '',
    description = '',
    imgUrls = [],
    published_time = new Date(),
    category = '',
    versions = [] as Version[],
    condition = '',
    numberInStock = 0
  ) {
    this._id = id
    this.name = name
    this.description = description
    this._imgUrls = imgUrls
    this.published_time = published_time
    this.category = category
    this.versions = versions
    this.condition = condition
    this.numberInStock = numberInStock
  }
}
