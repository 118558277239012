import { ActionTypes } from '../actions'

const initialState = {}
const contactPageReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.FETCH_CONTACT: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}
export default contactPageReducer
