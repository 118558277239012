import { makeStyles } from '@material-ui/core'

const mobileWidth = '80%'
const desktopWidth = 230
export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        display: 'block',
        position: 'relative',
        top: 50,
        boxShadow: '0 10px 10px 0 rgba(0,0,0,0.1)',
        [theme.breakpoints.up('sm')]: {
          top: 'unset',
        },
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
      },
      navBar: {
        maxWidth: 1920,
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        top: 0,
        height: 50,
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        [theme.breakpoints.up('sm')]: {
          flexWrap: 'unset',
        },
      },
      logoSection: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        fontSize: 17,
        textTransform: 'uppercase',
        background: theme.palette.primary.dark,
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        boxSizing: 'border-box',
        [theme.breakpoints.up('sm')]: {
          width: desktopWidth,
        },
      },
      otherSection: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: 50,
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
        [theme.breakpoints.up('sm')]: {
          flexGrow: 1,
          width: 'unset',
          height: 'unset',
          boxShadow: 'unset',
        },
      },
      drawer: {
        width: mobileWidth,
        flexShrink: 0,
        position: 'relative',
        margin: 0,
        padding: 0,
        [theme.breakpoints.up('sm')]: {
          width: desktopWidth,
        },
      },
      drawerPaper: {
        width: mobileWidth,
        position: 'relative',
        overflowY: 'unset',
        border: 'none',
        [theme.breakpoints.up('sm')]: {
          width: desktopWidth,
        },
      },
      drawerHeader: {
        flexDirection: 'column',
        display: 'block',
        overflow: 'scroll',
        alignItems: 'center',
        height: '100%',
        minHeight: `calc(100vh - 50px)!important`,
        justifyContent: 'flex-end',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          overflow: 'unset',
          boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
        },
      },
      content: {
        flexGrow: 1,
        padding: '10px 0px',
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),

        [theme.breakpoints.up('sm')]: {
          padding: '10px 5%',
        },

        [theme.breakpoints.up('md')]: {
          marginLeft: -desktopWidth,
        },
      },
      contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
      tabs: {
        width: '100%',
        height: 'auto',
        minHeight: '100%',
        boxSizing: 'border-box',
        cursor: 'pointer',
        // borderTop: `1px solid ${theme.palette.primary.light}`,
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
        background: `url(https://i.pinimg.com/564x/c3/58/16/c35816a35da906846b5f74d424c1cd1f.jpg)`,
        [theme.breakpoints.up('md')]: {
          borderTop: '1px solid rgba(255,255,255)',
        },
      },
      overlay: {
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        background: 'rgba(0,0,0,0.8)',
      },
      tab: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: 50,
        paddingLeft: 20,
        textDecoration: 'none',
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        transition: 'all ease-out 0.3s',
        borderBottom: '1px solid #fff',
        boxSizing: 'border-box',
        background: 'rgba(0,0,0,0.5)',
        '&:hover': {
          background: theme.palette.primary.light,
          color: theme.palette.getContrastText(theme.palette.primary.light),
        },
      },
      tabDropdown: {
        position: 'relative',
      },
      innerTab: {
        paddingLeft: 40,
      },
      activeTab: {
        borderRight: `3px solid ${theme.palette.primary.main}`,
        background: theme.palette.primary.light,
        color: theme.palette.getContrastText(theme.palette.primary.light),
      },
      hamburgerWrapper: {
        marginLeft: 10,
        // [theme.breakpoints.up('md')]: {
        //   display: 'none',
        // },
      },
      logoutSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        right: 20,
        top: '50%',
        transform: 'translateY(-50%)',
        color: theme.palette.primary.dark,
        fontSize: 14,
        cursor: 'pointer',
      },
      iconColor: {
        color: theme.palette.primary.dark,
      },
      dropdownIcon: {
        position: 'absolute',
        right: 5,
        top: '50%',
        transform: 'translateY(-50%)',
      },
    }
  },
  { name: 'cms', index: 1 }
)
