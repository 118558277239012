import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        /** Box */
        width: 180,
        height: 40,
        /** Typography */
        fontFamily: 'Roboto, sans-serif',
        color: 'white',
        fontSize: 16,
        textAlign: 'center',
        fontWeight: 300,
        textTransform: 'none',
        /** Visual */
        borderRadius: 5,
        zIndex: 2,
        backgroundImage:
          'linear-gradient(to right, #000000 0%, #434343  51%, #000000  100%)',
        backgroundSize: '200% auto',
        transition: 'all 0.3s ease-out',

        '&:hover': {
          backgroundPosition:
            'right center' /* change the direction of the change here */,
        },

        /** Media */
        [theme.breakpoints.down('sm')]: {
          fontSize: 15,
        },
        [theme.breakpoints.down('xs')]: {
          /** Typo */
          fontSize: 14,
        },
      },
    }
  },
  { name: 'button', index: 1 }
)
