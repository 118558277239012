import React from 'react'
import { useRealm } from '../../CommonModule/hooks/useStitch'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../reducers'
import { Product } from '../DataModels/Products'
import { ActionTypes } from '../actions'
import { Category } from '../DataModels/Categories'
import { toBase64 } from '../../CommonModule/hooks/useUtilities'
import useCategories from './useCategories'
import AppConfig from '../../AppConfig'
import useAWS from './useAWS'
import { BSON } from 'realm-web'

const moduleName = 'ProductModule'
const collectionName = 'Products'
const useProduct = () => {
  const realm = useRealm()
  const { handleUploadImages, deleteFiles } = useAWS()
  const dispatch = useDispatch()
  const content = useSelector((state: RootState) => state.productReducer)
  const { findCategoriesByIds, findCategoryById } = useCategories()

  /** Fetch data from Stitch & update to redux store */
  const fetchProducts = async () => {
    const query = {}
    // const rawProducts: unknown[] = await await realm.callFunction('fetchProducts')
    const rawProducts =
      (await (await realm.getCollection(moduleName, collectionName))?.find()) ||
      ([] as unknown[])
    // console.log(rawCategories)
    // console.log(mapToProductList(data))
    dispatch({
      type: ActionTypes.FETCH_PRODUCTS,
      payload: mapToProductList(rawProducts.reverse()),
    })
  }
  /** Map the fetched data into a list of Product */
  const mapToProductList = (fetchedContent: unknown[]) => {
    return fetchedContent.map((item: any) => {
      const prod = new Product(
        item._id,
        item.name,
        item.description,
        item._imgUrls,
        item.published_time,
        item.category,
        item.versions,
        item.condition,
        item.numberInstock
      )
      return prod
    })
  }

  const getProductsByName = (name: string) => {
    return content.products
      .filter((product) => {
        return product.name.toLowerCase().includes(name.toLowerCase().trim())
      })
      .slice(0, 5)
  }

  const findProductById = (id: string) => {
    return content.products.find((prod) => prod.id === id)
  }

  /** Update product to Realm DB. */
  const updateProduct = async (
    product: Product,
    imagesToUpload: (File | string)[],
    imagesToDelete: string[]
  ) => {
    const { imagesUrls: imgUrls, error } = await handleUploadImages(
      product.id,
      imagesToUpload,
      imagesToDelete
    )
    // console.log(imgUrls, error)

    // Stops the product upload progress if upload images fails
    if (error !== undefined) return

    try {
      product.imgUrls = imgUrls
      // insert new category to DB
      const result = await (
        await realm.getCollection(moduleName, collectionName)
      )?.findOneAndUpdate(
        { _id: new BSON.ObjectId(product.id) },
        {
          name: product.name,
          description: product.description,
          _imgUrls: imgUrls,
          published_time: product.published_time,
          category: product.category,
          versions: product.versions,
          condition: product.condition,
          numberInStock: product.numberInStock,
        },
        { upsert: true }
      )
      // dispatch a new action to update redux store
      dispatch({ type: ActionTypes.ADD_PRODUCT, payload: product })
      // console.log('Response:', result)
      return result
    } catch (error) {
      console.log('An error occurs, failed to upload Product!: ', error)
    }
  }

  /** Delete product from DB. */
  const deleteProduct = async (productId: string) => {
    const product = findProductById(productId)
    const imgKeys = product?.imgKeys
    try {
      const result = await (
        await realm.getCollection(moduleName, collectionName)
      )?.deleteOne({ _id: new BSON.ObjectId(productId) })
      
      // delete all images of this product in S3 bucket.
      imgKeys && (await deleteFiles(imgKeys))

      // update app state in redux store
      dispatch({ type: ActionTypes.DELETE_PRODUCT, payload: productId })
    } catch (err) {
      console.log('An error occurs, failed to delete Product!: ', err)
      return err
    }
  }

  interface Filters {
    price: number[]
    categoryIds: string[]
    conditions: string[]
    sortBy: SortBy
  }
  /** Get list of filtered products */
  const getFilteredProducts = (filters: Filters) => {
    if (!filters) return content.products

    const priceRange: number[] = filters.price || []
    const categories: Category[] = findCategoriesByIds(filters.categoryIds)
    const conditions = filters.conditions || []
    const sortBy = filters.sortBy || 'name'
    // console.log(filters)

    // console.log(content, priceRange, categories)
    const filteredProduct = content.products.filter((prod) => {
      const prodCate = findCategoryById(prod.category)
      return (
        // filter product that has category matching the filters
        (categories.length !== 0
          ? prod.category !== '' &&
            categories.some((cate) => prodCate?.fullPath.includes(cate.id))
          : true) &&
        // filter product condition that matches the filters
        (conditions.length !== 0
          ? conditions.some((cond) => cond === prod.condition)
          : true) &&
        // filter product that has at least one version in the price range
        (priceRange.length !== 0
          ? prod.versions.length !== 0 &&
            prod.versions.some(
              (ver) =>
                ver.price &&
                ver.price?.value >= priceRange[0] &&
                ver.price?.value <= priceRange[1]
            )
          : true)
      )
    })
    // return filteredProduct
    // console.log(filteredProduct, sortProducts(filteredProduct, sortBy))
    return sortProducts(filteredProduct, sortBy)
  }

  /** Sort products by specific field */
  type SortBy = 'name' | 'displayPrice' | 'published_time'
  const sortProducts = (
    products: Product[],
    sortBy: SortBy = 'published_time',
    order: 'asc' | 'desc' = 'asc'
  ) => {
    return products.sort((a, b) => {
      switch (sortBy) {
        case 'name': {
          // sortby product's name
          // if (a[sortBy] > b[sortBy]) return order === 'asc' ? 1 : -1
          // if (a[sortBy] < b[sortBy]) return order === 'asc' ? -1 : 1
          // return 0
          return a.name.localeCompare(b.name)
        }
        case 'displayPrice': {
          // sortby product's display price
          return a[sortBy] - b[sortBy]
        }

        default: {
          // sort by published_time
          return order === 'asc'
            ? a.published_time.getTime() - b.published_time.getTime()
            : b.published_time.getTime() - a.published_time.getTime()
        }
      }
    })
  }

  /** Get list of the most recent updated products */
  const getNewArrivals = (quantity: number): Product[] => {
    const newArr = content.products
      .sort((a: Product, b: Product) => {
        return b.published_time?.getTime() - a.published_time?.getTime()
      })
      .slice(0, quantity)
    return newArr
  }

  /** Add a filter to the filters list */
  const updateFilters = (fieldName: keyof Filters, query: any) => {
    dispatch({
      type: ActionTypes.UPDATE_FILTERS,
      payload: { [fieldName]: query },
    })
  }

  /** Update filtered products list when the original list is changed */
  React.useEffect(() => {
    let filteredProducts: Product[] = getFilteredProducts(content.filters)
    // console.log('filters list has changed', filters, filteredProducts)
    dispatch({
      type: ActionTypes.FILTER_PRODUCTS,
      payload: filteredProducts,
    })
  }, [content.products, content.filters])

  return {
    content,
    getProductsByName,
    fetchProducts,
    getNewArrivals,
    updateFilters,
    updateProduct,
    deleteProduct,
    findProductById,
  }
}
export default useProduct
