import React, { useState, useEffect, FC } from 'react'
import { Box } from '@material-ui/core'

import Arrow from '../../../CommonModule/Arrow'
import useDevice, { ScreenSizes } from '../../../CommonModule/hooks/useDevice'
import { useStyles } from './Carousel.style'
import ProductCard from '../ProductCard'
import { Product } from '../../DataModels/Products'
import { ReviewCard, Review } from '../Review'
import ProductDetailsImage from '../ProductDetails/ProductDetailsImage'
import { imgUrlObjects } from '../ProductDetails/ProductDetails.component'

/** SECTION - Main Components */

const Indicator = (props: {
  index: number
  activeIndex: number
  handleClick: Function
}) => {
  const { index, activeIndex, handleClick } = props
  const classes = useStyles()
  /** Set active class by the corresponding image index */
  const classNames = `${classes.indicator} ${
    activeIndex === index ? classes.indicatorActive : ''
  }`
  return (
    <>
      <label className={classNames} onClick={() => handleClick(index)}></label>
    </>
  )
}

interface CarouselProps {
  items: (Product | Review | imgUrlObjects)[]
  itemTemplate:
    | typeof ProductCard
    | typeof ReviewCard
    | typeof ProductDetailsImage
  itemPropName: string
  autoSlide?: boolean
  xsBlockSize?: number
  smBlockSize?: number
  mdBlockSize?: number
  lgBlockSize?: number
  xlBlockSize?: number
  showArrow?: boolean
  flexComponent?: boolean
}

const Carousel: FC<CarouselProps> = ({
  items,
  itemTemplate,
  itemPropName,
  autoSlide = false,
  smBlockSize = 1,
  mdBlockSize = 1,
  lgBlockSize = 1,
  xlBlockSize = 1,
  showArrow = true,
  flexComponent = true,
}) => {
  const { getScreenSize } = useDevice()
  const [currentIndex, setCurrentIndex] = useState(0)
  const classes = useStyles()

  /** calculate the blockSize based on device screen size */
  const getBlockSize = () => {
    const defaultBlockSize = 1
    const screenSize = getScreenSize()
    switch (screenSize) {
      case ScreenSizes.xs:
        return defaultBlockSize
      case ScreenSizes.sm:
        return smBlockSize || defaultBlockSize
      case ScreenSizes.md:
        return mdBlockSize || defaultBlockSize
      default:
        return lgBlockSize || defaultBlockSize
    }
  }
  const blockSize = getBlockSize()

  const getPrevIndex = () => {
    const lastBlockStartIndex = blockSize * Math.floor((items.length - 1) / blockSize)
    const prevIndex = currentIndex - blockSize
    // console.log(prevIndex < 0 ? lastBlockStartIndex : prevIndex)
    return prevIndex < 0 ? lastBlockStartIndex : prevIndex
  }
  const getNextIndex = () => {
    return currentIndex + blockSize >= items.length
      ? 0
      : currentIndex + blockSize
  }

  const goBackward = () => setCurrentIndex(getPrevIndex())
  const goForward = () => setCurrentIndex(getNextIndex())
  const selectIndex = (index: number) =>
    setCurrentIndex((index * blockSize) % items.length)

  /** render image slider with size of blockSize */
  const renderSlider = () => {
    const start = (currentIndex + items.length) % items.length
    const end =
      start + blockSize >= items.length ? items.length : start + blockSize
    let itemsToShow: typeof items = []
    for (let counter = start; counter < end; counter++) {
      itemsToShow = [...itemsToShow, items[counter % items.length]]
    }

    return (
      <Box
        className={
          flexComponent
            ? classes.flexComponentWrapper
            : classes.blockComponentWrapper
        }
      >
        {itemsToShow.map((item) => {
          let itemComponent = React.createElement(
            itemTemplate as React.FunctionComponent,
            {
              key: item.id,
              [itemPropName]: item,
            }
          )
          return itemComponent
        })}
      </Box>
    )
  }
  /** render indicators by block based on the number of images */
  const renderIndicators = () => {
    const numOfBlocks = Math.floor(
      items.length / blockSize + (items.length % blockSize === 0 ? 0 : 1)
    )
    let indicators: any = []
    for (let ind = 0; ind < numOfBlocks; ind++) {
      indicators = [
        ...indicators,
        <Indicator
          key={ind}
          index={ind}
          activeIndex={Math.ceil(currentIndex / blockSize)}
          handleClick={selectIndex}
        />,
      ]
    }
    return <Box className={classes.indicatorWrapper}>{indicators}</Box>
  }

  useEffect(() => {
    /** update next image index every 3s */
    if (!autoSlide) return
    let interval = setInterval(() => {
      goForward()
    }, 3000)
    /** clean subscription before unmount component */
    return () => {
      if (!interval) return
      clearInterval(interval)
    }
  }, [])

  return (
    items.length === 0 ? null : (
      <>
        <Box className={classes.carouselWrapper}>
          {(showArrow && items.length > blockSize) && (
            <Box className={classes.arrow}>
              <Arrow direction='left' handleClick={goBackward} />
            </Box>
          )}
          <Box className={classes.main}>
            {renderSlider()}
            {items.length > blockSize && renderIndicators()}
          </Box>
          {(showArrow && items.length > blockSize) && (
            <Box className={classes.arrow}>
              <Arrow direction='right' handleClick={goForward} />
            </Box>
          )}
        </Box>
      </>
    )
  )
}
export default Carousel
