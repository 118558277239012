import React from "react"
import { Message } from "."
import useError from "../hooks/useError"

const ErrorMessage = () => {
  const {error, removeError} = useError()
  return (
    <Message open={!!error.message} message={error.message} onClose={removeError} />
  )
}
export default ErrorMessage