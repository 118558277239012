import React from 'react'
import { Box } from '@material-ui/core'
import { useStyles } from './CampaignProduct.style'
import NeroButton from '../../../CommonModule/NeroButton'
import Float from '../../../CommonModule/Float'
import { Link } from 'react-router-dom'

interface Item {
  title: string
  desc: string
  imgUrl: string
  btnLink?: string
  left?: boolean
}

interface CampaignProductProps {
  item: Item
}
export const CampaignProduct = ({ item }: CampaignProductProps) => {
  const itemOrder = item.left ? 'row' : 'row-reverse'
  const classes = useStyles({ left: item.left })

  const renderImage = (imgUrl: string) => {
    return (
      <Float
        direction={item.left ? 'right' : 'left'}
        // rootMargin='-10%'
        offsetPosition={50}
        timeout={1500}
      >
        <Box className={classes.backgroundImg}>
          <img className={`${classes.image}`} src={imgUrl} alt='' />
        </Box>
      </Float>
    )
  }

  const renderTextBox = (title: string, desc: string, btnLink?: string) => {
    return (
      <Float
        direction='up'
        // rootMargin='-10%'
        offsetPosition={50}
        timeout={1500}
      >
        <Box className={classes.textBox}>
          <p className={classes.title}>{title}</p>
          <p className={classes.description}>{desc}</p>
          {btnLink && (
            <a
              style={{ textDecoration: 'none' }}
              target='_blank'
              href={btnLink}
            >
              <NeroButton>Xem thêm</NeroButton>
            </a>
          )}
        </Box>
      </Float>
    )
  }

  const renderComponent = (item: Item) => {
    return (
      <Box className={classes.root}>
        <Box className={classes.innerWrapper} flexDirection={itemOrder}>
          {renderImage(item.imgUrl)}
          {renderTextBox(item.title, item.desc, item.btnLink)}
        </Box>
      </Box>
    )
  }
  return <>{renderComponent(item)}</>
}

interface CampaignSectionProps {
  items: Item[]
}
const CampaignSection = ({ items = [] }: CampaignSectionProps) => {
  return (
    <>
      {items.map((item, index) => (
        <CampaignProduct key={`CampProd-${index}`} item={item} />
      ))}
    </>
  )
}

export default CampaignSection
