import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    productImageWrapper: {
      position: 'relative',
      top: '5%',
      width: '100%',
      paddingBottom: '100%',
    },
    productImage: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
  }
})
