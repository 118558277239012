import React from 'react'

const useScroll = () => {
  const scrollToTop = React.useCallback(() => {
    window.scrollTo(0, 0)
  }, [])

  return { scrollToTop }
}

export default useScroll