import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles(
  (theme: Theme) => {
    return {
      root: {
        height: 'inherit',
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '2%',
        paddingRight: '2%',
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
        },
      },
      title: {
        fontWeight: 600,
        textTransform: 'uppercase',
      },
      categoriesSection: {
        flexGrow: 1,
        textAlign: 'start',
        paddingBottom: '2%',
      },
      categoriesList: {
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
        paddingTop: 0,
        paddingBottom: 0,

        [theme.breakpoints.up('md')]: {
          marginRight: 20,
        },
      },
      productsSection: {
        flexGrow: 2,
        textAlign: 'start',
        paddingBottom: '2%',
      },
      productsList: {
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
        paddingTop: 0,
        paddingBottom: 0,
      },

      listItem: {
        paddingRight: '8px',
        paddingLeft: '8px',
        '&:hover': {
          transition: 'all ease-out 0.3s',
          boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
          backgroundColor: 'transparent',
        },
      },
      noResultBox: {
        height: 40,
        display: 'flex',
        alignItems: 'center',
      },
      item: {
        height: 70,
        color: 'inherit',
        textDecoration: 'none',
        [theme.breakpoints.up('md')]: {
          height: 100,
        },

        '& .MuiAvatar-root': {
          width: 50,
          height: 50,
          [theme.breakpoints.up('md')]: {
            width: 80,
            height: 80,
          },
        },

        '& .MuiListItemText-root': {
          paddingLeft: 'inherit',
        },
      },
      itemText: {
        '& .MuiTypography-colorTextSecondary': {
          color: '#ffb400',
        },
      },
    }
  },
  { index: 1 }
)
