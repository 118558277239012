import { Box, Icon } from '@material-ui/core'
import React from 'react'
import { useStyles } from './ImagePreviewPane.style'

type ImageType = File | string
interface Props {
  files?: ImageType[]
  handleRemove: Function
}
// type ImgUrl = (string | ArrayBuffer | null)
const ImagePreviewPane: React.FC<Props> = React.memo(({ files = [], handleRemove }) => {
  const getImgSrc = (img: ImageType) => {
    return typeof img === 'string' ? img : URL.createObjectURL(img)
  }

  const getImgName = (img: ImageType) => {
    const getNameRegEx = '[^/]*$'
    // typeof img === 'string' &&  console.log(img.match(getNameRegEx))
    return typeof img === 'string' ? img.match(getNameRegEx)?.[0] : img.name
  }

  const handleImgLoad = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    // revoke to ObjectURL once the image is loaded
    URL.revokeObjectURL(event.currentTarget.src)
  }
  // console.log(files)
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      {files.map((file, index) => (
        <Box key={index} className={classes.imageWrapper}>
          <Box
            className={classes.closeButtonWrapper}
            onClick={() =>
              handleRemove(typeof file === 'string' ? getImgName(file) : file)
            }
          >
            <Icon className={classes.closeButton}>close</Icon>
          </Box>
          <img
            className={classes.image}
            style={{ aspectRatio: '1/1' }}
            src={getImgSrc(file)}
            onLoad={handleImgLoad}
            alt={''}
          />
          <Box className={classes.imageName}>{getImgName(file)}</Box>
        </Box>
      ))}
    </Box>
  )
})

export default ImagePreviewPane
