import React from 'react'
import {
  Box,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { useStyles } from './ProductDetails.style'
import { Product } from '../../DataModels/Products'
import NeroButton from '../../../CommonModule/NeroButton'
import Carousel from '../Carousel'
import ProductDetailsImage from './ProductDetailsImage'

export interface imgUrlObjects {
  id: string
  imgUrl: string
}

interface ProductListItemProps {
  icon: string
  title: string
  detail: string
  lastItem?: boolean
}

const ProductListItem = ({ icon, title, detail }: ProductListItemProps) => {
  const classes = useStyles()

  return (
    <>
      <ListItem className={classes.listItem}>
        <ListItemAvatar>
          <Avatar className={classes.listItemIconBackground}>
            <Icon>{icon}</Icon>
          </Avatar>
        </ListItemAvatar>
        {detail && <ListItemText primary={title} secondary={detail} />}
      </ListItem>
      <Divider component='li' />
    </>
  )
}

interface ProductDetailsProps {
  product: Product
}
const ProductDetails = ({ product }: ProductDetailsProps) => {
  const classes = useStyles()
  let {
    id,
    name,
    imgUrls,
    displayPrice,
    condition,
    description,
    versions,
  } = product
  const productAttributes = [
    {
      icon: 'device_unknown_icon',
      title: 'Tình trạng',
      detail: `${condition}`,
    },
    { icon: 'shopping_basket_icon', title: 'Trạng thái', detail: 'còn hàng' },
    {
      icon: 'description_icon',
      title: 'Miêu tả sản phẩm',
      detail: `${description}`,
    },
  ]

  let imgUrlObjects: imgUrlObjects[] = []
  imgUrls.forEach((imgUrl, index) => {
    imgUrlObjects.push({ id: `${id}-${index}`, imgUrl: imgUrl })
  })

  const [selectedPrice, setSelectedPrice] = React.useState(`${displayPrice}`)
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedPrice(event.target.value as string)
  }

  return (
    <Box>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          lg={7}
          className={classes.additionalMargin}
        >
          <Box className={classes.productImageWrapper}>
            <Box width='90%' height='99%' margin='auto'>
              <Box width='100%' height='100%'>
                <Carousel
                  mdBlockSize={1}
                  items={imgUrlObjects}
                  itemTemplate={ProductDetailsImage}
                  itemPropName='detailImage'
                  flexComponent={false}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid container item xs={12} lg={5}>
          <Box className={classes.productInfoWrapper}>
            <h1 className={classes.productName}>{name}</h1>
            <Box className={classes.productPrice}>
              {Number(selectedPrice).toLocaleString()}{' '}
              {versions[0] && versions[0].price?.currency}
            </Box>
            {versions.length > 1 && (
              <TextField
                className={classes.selector}
                variant='outlined'
                size='small'
                select
                value={selectedPrice}
                onChange={handleChange}
                label='Phiên bản'
              >
                {versions.map((version, index) => {
                  return (
                    <MenuItem key={index} value={version.price?.value}>
                      {version.color} {version.storageSize}
                    </MenuItem>
                  )
                })}
              </TextField>
            )}
            <List>
              {productAttributes.map((attributes, index) => (
                <ProductListItem
                  key={index}
                  icon={attributes.icon}
                  title={attributes.title}
                  detail={attributes.detail}
                />
              ))}
            </List>
            <a style={{ textDecoration: 'none' }} href={`tel:+84 888234234`}>
              <NeroButton className={classes.button}>Gọi mua hàng</NeroButton>
            </a>

            {/* <NeroButton className={classes.button} variant='secondary'>
              Add to wishlist
            </NeroButton> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ProductDetails
