import { ActionTypes } from '../actions'
import { Category } from '../DataModels/Categories'

const initialState = {
  categories: [] as Category[],
}

const categoryReducer = (
  state = initialState,
  action: { type: ActionTypes; payload: Category | Category[] | undefined | string }
) => {
  switch (action.type) {
    case ActionTypes.FETCH_CATEGORIES: {
      return {
        ...state,
        categories: action.payload as Category[],
      }
    }
    case ActionTypes.ADD_CATEGORY: {
      return {
        ...state,
        categories: [
          ...state.categories.filter(
            (cate) => cate.id !== (action.payload as Category).id
          ),
          action.payload as Category,
        ],
      }
    }
    case ActionTypes.DELETE_CATEGORY: {
      return {
        ...state,
        categories: state.categories.filter(cate => cate.id !== action.payload)
      }
    }
    case ActionTypes.ADD_CATEGORIES: {
      return {
        ...state,
        categories: [...state.categories, ...action.payload as Category[]],
      }
    }
    default:
      return state
  }
}

export default categoryReducer
