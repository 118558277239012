import { ActionTypes } from '../actions'

const initialState = {}

const aboutPageReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.FETCH_ABOUTPAGE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export default aboutPageReducer
