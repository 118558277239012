import {
  Avatar,
  Fade,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'
import React, { FC } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Category } from '../../DataModels/Categories'
import { Product } from '../../DataModels/Products'
import useProduct from '../../hooks/useProduct'
import { useStyles } from './SearchResult.style'
import '../../../i18n'
import { useTranslation } from 'react-i18next'

interface Result {
  products: Product[]
  categories: Category[]
}
interface Props {
  result: Result
}

const SearchResult: FC<Props> = ({
  result = { products: [], categories: [] },
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { updateFilters } = useProduct()
  const { t } = useTranslation()
  // console.log('result: ', result)

  /** Handle when user click out the suggested category */
  const handleCategorySearch = (cateId: string) => {
    // console.log(cateId)
    // add selected category to the filter list
    updateFilters('categoryIds', [cateId])
    // redirect to the product page
    history.push({
      pathname: '/products',
    })
  }

  /** Categories section */
  const renderCategories = () => (
    <Fade
      in={result.categories.length !== 0}
      timeout={500}
      mountOnEnter
      unmountOnExit
    >
      <div className={classes.categoriesSection}>
        <h3 className={classes.title}>{t('category.category')}</h3>
        <List className={classes.categoriesList}>
          {result.categories.map((cate, index) => (
            <div key={index} onClick={() => handleCategorySearch(cate.id)}>
              <ListItem className={classes.listItem} button>
                <ListItemText primary={cate.name} />
              </ListItem>
            </div>
          ))}
        </List>
      </div>
    </Fade>
  )
  /** Products section */
  const renderProducts = () => (
    <Fade
      in={result.products.length !== 0}
      timeout={500}
      mountOnEnter
      unmountOnExit
    >
      <div className={classes.productsSection}>
        <h3 className={classes.title}>{t('product.product')}</h3>
        <List className={classes.productsList}>
          {result.products.map((item) => (
            <NavLink
              key={item.id}
              className={classes.item}
              to={`/products/${item.id}`}
            >
              <ListItem className={classes.listItem} button>
                <ListItemAvatar>
                  <Avatar src={item.imgUrls[0]} variant='square' />
                </ListItemAvatar>
                <ListItemText
                  className={classes.itemText}
                  primary={item.name}
                  secondary={`${Number(item.displayPrice).toLocaleString()} ${
                    item.displayCurrency
                  }`}
                />
              </ListItem>
            </NavLink>
          ))}
        </List>
      </div>
    </Fade>
  )

  return (
    <div className={classes.root}>
      {result.products.length === 0 && result.categories.length === 0 ? (
        <div className={classes.noResultBox}>{t('common.noResultFound')}</div>
      ) : (
        <>
          {renderCategories()}
          {renderProducts()}
        </>
      )}
    </div>
  )
}

export default SearchResult
