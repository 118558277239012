import React from 'react'
import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core'

/** SECTION - JSS classes */
const useStyles = makeStyles(
  (theme: Theme) => {
    return createStyles({
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
    })
  },
  { name: 'spinner', index: 1 }
)

/** SECTION - Main Components */
/** */
interface LoadingSpinnerProps {
  open: boolean
}
const LoadingSpinner = ({ open }: LoadingSpinnerProps) => {
  const classes = useStyles()
  return (
    <>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress size={100} disableShrink />
      </Backdrop>
    </>
  )
}

export default LoadingSpinner
