import { useEffect, useState } from 'react'

/** Return a debounce value after a delay in miliseconds. */
const useDebounce = <T extends unknown>(value: T, delay: number = 0) => {
  const [debounceValue, setDebounceValue] = useState<T>(value)

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebounceValue(value)
    }, delay)

    /** cleanup the timeout before updating new debounceValue
     * when everytime a new value come in */
    return () => clearTimeout(debounceTimeout)
  }, [value, delay])
  return debounceValue
}
export default useDebounce
