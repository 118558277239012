import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  (theme) => {
    const sizes = {
      xs: {
        right: { h: 189, w: 300 },
        left: { h: 189, w: 259 },
        leftBg: { h: 241, w: 208 },
      },
      sm: {
        right: { h: 460, w: 623 },
        left: { h: 460, w: 544 },
        leftBg: { h: 569, w: 484 },
      },
      md: {
        right: { h: 460, w: 623 },
        left: { h: 460, w: 525 },
        leftBg: { h: 569, w: 476 },
      },
    }

    return {
      root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        height: 'auto',
        [theme.breakpoints.down('md')]: {
          marginBottom: theme.spacing(3),
        },
      },
      innerWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        width: 'calc(1440px - 340px)',
        [theme.breakpoints.down('md')]: {
          justifyContent: 'center',
        },
        [theme.breakpoints.up('lg')]: {
          boxShadow: '0 0 7px 0 rgba(0,0,0,0.1)',
        },

        marginBottom: 20,
      },
      textBox: (props: any) => ({
        /** Position */
        alignSelf: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        /** Box */
        height: '100%',
        display: 'flex',
        flexGrow: 1,
        // maxWidth: '30%',
        maxWidth: 400,
        /** Typography */
        color: 'black',
        textAlign: props.left ? 'left' : 'right',
        alignItems: props.left ? 'flex-start' : 'flex-end',
        [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
          /** Position */
          paddingRight: props.left ? '' : 50,
          paddingLeft: props.left ? 185 : '',
        },
        [theme.breakpoints.down('md')]: {
          /** Box */
          maxWidth: 623,
          /** Typography */
          alignItems: 'center',
          textAlign: 'center',
        },
      }),
      title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBlockEnd: 0,
        textTransform: 'uppercase',
        [theme.breakpoints.down('xs')]: {
          marginLeft: 5,
          marginRight: 5,
        },
      },
      description: {
        fontSize: 18,
        marginBlockStart: '35px',
        marginBlockEnd: '35px',
        lineHeight: '30px',
        [theme.breakpoints.down('xs')]: {
          marginBlockStart: '20px',
          marginBlockEnd: '20px',
          marginLeft: 20,
          marginRight: 20,
        },
      },
      backgroundImg: (props: any) => ({
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        /** Position */
        position: 'relative',
        /** Box */
        height: props.left ? sizes.md.leftBg.h : sizes.md.right.h,
        width: '100%',
        maxWidth: props.left ? sizes.md.leftBg.w : sizes.md.right.w,
        /** Visual */
        backgroundColor: props.left ? 'black' : 'transparent',
        // backgroundImage: props.left ? `url(https://images.pexels.com/photos/924824/pexels-photo-924824.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500)` : '',
        [theme.breakpoints.down('md')]: {
          /** Position */
          left: props.left
            ? `calc(((-${(sizes.sm.left.w + 80 - sizes.sm.leftBg.w) / 2}px)))`
            : '',
          /** Box */
          height: props.left ? sizes.sm.leftBg.h : sizes.sm.right.h,
          maxWidth: props.left ? sizes.sm.leftBg.w : sizes.sm.right.w,
        },
        [theme.breakpoints.down('xs')]: {
          /** Position */
          left: props.left
            ? `calc(((-${(sizes.xs.left.w + 40 - sizes.xs.leftBg.w) / 2}px)))`
            : '',
          /** Box */
          height: props.left ? sizes.xs.leftBg.h : sizes.xs.right.h,
          maxWidth: props.left ? sizes.xs.leftBg.w : sizes.xs.right.w,
        },
      }),
      image: (props: any) => ({
        /** Position */
        position: 'relative',
        left: props.left ? 80 : '',
        top: props.left ? 65 : '',
        /** Box */
        height: 460,
        minWidth: props.left ? sizes.md.left.w : sizes.md.right.w,
        objectFit: 'cover',
        [theme.breakpoints.down('sm')]: {
          minWidth: props.left ? sizes.sm.left.w : sizes.sm.right.w,
        },
        [theme.breakpoints.down('xs')]: {
          /** Position */
          left: props.left ? 40 : '',
          top: props.left ? 30 : '',
          /** Box */
          width: props.left ? sizes.xs.left.w : sizes.xs.right.w,
          minWidth: props.left ? sizes.xs.left.w : sizes.xs.right.w,
          height: 190,
          maxWidth: 300,
        },
      }),
    }
  },
  { name: 'campaign', index: 1 }
)
