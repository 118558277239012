import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  (theme) => ({
    wrapper: {},
    customCarousel: {
      background: '#000',
      '& .slick-track': {
        padding: '70px 0px',
        background: theme.palette.primary.dark,
        display: 'flex',
        alignItems: 'center',
      },
      '& .slick-slide': {
        opacity: 0.5,
        transition: 'all 0.3s linear',
      },
      '& .slick-center': {
        opacity: 1,
        transform: 'scale(1.4)',
      },
      '& .slick-prev': {
        width: 40,
        height: 40,
        zIndex: 999,
        left: '5%',
        '&:before': {
          fontSize: 40,
          color: theme.palette.primary.main,
        },
      },
      '& .slick-next': {
        width: 40,
        height: 40,
        zIndex: 999,
        right: '7%',
        '&:before': {
          fontSize: 40,
          color: theme.palette.primary.main,
        },
      },
    },
    tile: {
      padding: '2%',
      width: 'fit-content',
      margin: '30px auto',
      maxWidth: '90%',
      '& img': {
        display: 'block',
        width: '100%',
        height: '100%',
      },
    },
  }),
  { name: 'socialMedia', index: 1 }
)
