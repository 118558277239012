import { makeStyles, Theme, createStyles } from '@material-ui/core'

export /** SECTION - JSS classes */
const useStyles = makeStyles(
  (theme: Theme) => {
    const mainColor = '#FFB400'
    const duration = 0.5
    return createStyles({
      wrapper: {
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '90%',
        [theme.breakpoints.up('md')]: {
          width: 600,
        },
      },
      clickable: {
        /** Misc */
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
          /// Hides close icon on small device (fullScreen overlay)
          display: 'none',
        },
      },
      text: {
        width: 'inherit',
        '& input': {
          /** Position */
          position: 'relative',
          top: 2,
          height: 20,
        },
        /** Typo */
        '& input, & .MuiIcon-root': {
          color: 'white',
        },
        '& label.Mui-focused': {
          color: mainColor,
        },
        '& .MuiInput-root': {
          height: 44,
          padding: '0 10px',
        },
        '& .MuiInputAdornment-positionEnd': {
          position: 'relative',
          top: 3.5,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
          borderBottomWidth: 1,
          borderBottomColor: mainColor,
        },
        '& .MuiInput-underline:before, & .MuiInput-underline:after': {
          borderBottomColor: mainColor,
        },
      },
      searchField: {
        /** Box */
        height: '100%',
        /** Misc */
        transition: `width ${duration}s ease-in-out, opacity ${duration}s ease-in-out`,
      },
      result: {
        position: 'fixed',
        top: 100,
        height: 'fit-content',
        width: 'inherit',
        zIndex: 3,
        [theme.breakpoints.up('md')]: {
          top: 50,
        },
      },
    })
  },
  { name: 'search', index: 1 }
)
