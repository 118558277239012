import AppConfig from '../../AppConfig'
import {useRealm} from '../../CommonModule/hooks/useStitch'
import useAWS from './useAWS'

const moduleName = 'ProductModule'
const collectionName = 'HomePage'
const sectionName = 'gallerySection'
const baseUrl = AppConfig.cloudfrontUrl
export interface ImageTile {
  img: string
}

const useGallery = () => {
  const realm = useRealm()
  const { handleUploadImages } = useAWS()

  /** Fetch Gallery data. */
  const fetchGallery = async () => {
    try {
      const data = await (
        await realm.getCollection('ProductModule', 'HomePage')
      )?.find({ name: sectionName })
      return data
        ?.flatMap((data) => data.imageTiles)
        .flatMap((tile) => `${baseUrl}/${tile.img}`)
    } catch (err) {
      throw new Error(err)
    }
  }

  /** Update the gallery data. */
  const updateGallery = async (
    imagesToUpload: (File | string)[],
    imageKeysToDelete: string[]
  ) => {
    // upload files & get all the keys
    const { imagesUrls: imgKeys, error } = await handleUploadImages(
      sectionName,
      imagesToUpload,
      imageKeysToDelete
    )

    // Stops the upload process if uploading images fails
    if (error !== undefined) return

    try {
      const response = await (
        await realm.getCollection(moduleName, collectionName)
      )?.findOneAndUpdate(
        {
          name: sectionName,
        },
        {
          $set: {
            imageTiles: imgKeys.map((key) => ({ img: key })),
          },
        }
      )
      return response
    } catch (err) {
      throw new Error(err)
    }
  }

  return { fetchGallery, updateGallery }
}

export default useGallery
