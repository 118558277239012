import React, { Fragment } from 'react'

import Banner from '../components/Banner/Banner.component'
import SocialMedia from '../components/SocialMedia'
import Parallax from '../components/Parallax'
import Review from '../components/Review'
import NewArrivals from '../components/NewArrivals'
import { Box } from '@material-ui/core'
import useScroll from '../hooks/useScroll'
import ContactAndMap from '../components/ContactAndMap'
import CategoryNavigation from '../components/CategoryNavigation'
import useProduct from '../hooks/useProduct'
import useHomePage from '../hooks/useHomePage'
import CampaignSection from '../components/CampaignProduct'
import AppConfig from '../../AppConfig'
const baseImgUrl = AppConfig.cloudfrontUrl
const Home = () => {
  const { scrollToTop } = useScroll()
  const products = useProduct().getNewArrivals(12)
  const { content } = useHomePage()
  const { parallax, campaignSection, gallerySection } = content

  const reviews = [
    { id: 1, content: 'Content 1', author: 'Tui' },
    {
      id: 2,
      content:
        'Content 2Content 2Content 2Content 2Content 2Content 2Content 2',
      author: 'Ta',
    },
    { id: 3, content: 'Content 3' },
  ]

  scrollToTop()
  return (
    <Fragment>
      <CategoryNavigation />
      <Banner />
      <Box marginTop={8}>
        <NewArrivals products={products} />
      </Box>
      <Box marginTop={10}>
        <Parallax content={parallax} />
      </Box>
      <Box marginTop={12}>
        <CampaignSection items={campaignSection?.campaigns} />
      </Box>
      <Box marginTop={8}>
        <SocialMedia
          tiles={gallerySection?.imageTiles.map((tile) => ({
            ...tile,
            img: `${baseImgUrl}/${tile.img}`,
          }))}
        />
      </Box>
      {/* <Box marginTop={8}>
        <Review reviews={reviews} />
      </Box> */}
      <Box
        marginTop={8}
        marginBottom={8}
        marginLeft={{ xs: '15px', sm: '5%', md: '10%', lg: '20%', xl: '30%' }}
        marginRight={{ xs: '15px', sm: '5%', md: '10%', lg: '20%', xl: '30%' }}
      >
        <ContactAndMap></ContactAndMap>
      </Box>
    </Fragment>
  )
}

export default Home
