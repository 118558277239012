import { RealmContextProps, useRealmApp } from '../MongodbRealm/RealmApp'
import React from 'react'
import AppConfig from '../../AppConfig'
const realmDBName = AppConfig.realmDBName
let db: globalThis.Realm.Services.MongoDBDatabase | undefined

type MongoDBContextProps = {
  realmDB: globalThis.Realm.Services.MongoDBDatabase | undefined
  getCollection: (
    moduleName: string,
    collectionName: string
  ) => Promise<
    globalThis.Realm.Services.MongoDB.MongoDBCollection<any> | undefined
  >
  updateCollection: (
    moduleName: string,
    collectionName: string,
    query: object,
    payload: any,
    options?: globalThis.Realm.Services.MongoDB.UpdateOptions
  ) => Promise<globalThis.Realm.Services.MongoDB.UpdateResult<any> | undefined>
} & Omit<RealmContextProps, 'realmUser'>
const MongoDBContext = React.createContext<MongoDBContextProps>(
  {} as MongoDBContextProps
)

const MongoDBProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    realmUser,
    authenticate,
    logOut,
    resetPassword,
    sendResetPasswordEmail,
  } = useRealmApp()
  const [realmDB, setRealmDB] = React.useState<typeof db>(undefined)
  React.useEffect(() => {
    if (realmUser) {
      setRealmDB(realmUser?.mongoClient('Nero-Core-Stitch').db(realmDBName))
    }
  }, [realmUser])

  /** fetch data from collection */
  const getCollection = async (moduleName: string, collectionName: string) => {
    return realmDB?.collection(`${moduleName}-${collectionName}`)
  }

  const updateCollection = async (
    moduleName: string,
    collectionName: string,
    query: object,
    payload: any,
    options?: globalThis.Realm.Services.MongoDB.UpdateOptions
  ) => {
    const updatedData = {
      $set: {
        ...payload,
      },
    }
    return realmDB
      ?.collection(`${moduleName}-${collectionName}`)
      .updateMany(query, updatedData, options)
  }

  return (
    <MongoDBContext.Provider
      value={{
        realmDB,
        getCollection,
        updateCollection,
        authenticate,
        logOut,
        resetPassword,
        sendResetPasswordEmail,
      }}
    >
      {children}
    </MongoDBContext.Provider>
  )
}
export const useRealm = () => {
  const mdbContext = React.useContext(MongoDBContext)
  if (mdbContext == null) {
    throw new Error('useMongoDB() called outside of a MongoDB?')
  }
  return mdbContext
}

export default MongoDBProvider
