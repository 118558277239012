import React from 'react'
import { Fade, Box, Paper, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useStyles } from './ProductCard.style'
import { Product } from '../../DataModels/Products'

interface ProductCardProps {
  product: Product
  width?: string
}

const ProductCard = ({ product, width }: ProductCardProps) => {
  const { id, name, imgUrls, displayPrice, displayCurrency, versions } =
    product || new Product()
  const classes = useStyles({ imgUrls })
  return (
    <>
      <Fade in timeout={1000} appear mountOnEnter unmountOnExit>
        <Box className={classes.cardWrapper} style={{ width: `${width}` }}>
          <Link style={{ textDecoration: 'none' }} to={`/products/${id}`}>
            <Paper className={classes.card}>
              <Box className={classes.imageWrapper}>
                <img
                  src={`${imgUrls[0]}`}
                  alt={name}
                  className={classes.image}
                  loading='lazy'
                >
                  {/* <Box className={classes.buttonWrapper}> */}
                  {/* <NeroButton className={classes.hidden}>Compare</NeroButton> */}
                  {/* <NeroButton className={classes.hidden}>
                    More Details
                  </NeroButton> */}
                  {/* </Box> */}
                </img>
              </Box>
              <Box className={classes.contentWrapper}>
                <Typography className={classes.productName} align='center'>
                  {name}
                </Typography>
                <Typography className={classes.price} align='center'>
                  {versions.length > 1 && 'Từ'}{' '}
                  {`${displayPrice.toLocaleString()} ${displayCurrency}`}
                </Typography>
              </Box>
            </Paper>
          </Link>
        </Box>
      </Fade>
    </>
  )
}

export default ProductCard
