import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  (theme) => {
    return {
      addDeleteButtonWrapper: {
        marginTop: 20,
        textAlign: 'center',
        '& > button': {
          margin: 5,
        },
        [theme.breakpoints.up('sm')]: {
          textAlign: 'left',
          '& > button': {
            margin: '0 0 0 10px',
          },
        },
      },
      productTableWrapper: {
        display: 'flex',
        height: 'auto',
        marginTop: 20,
        padding: '0 20px',
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
      },
      cellWrapper: {
        '& > *': {
          borderBottom: 'unset',
        },
      },
      tableCell: {
        padding: '10px 5px',
        minWidth: 100,
        [theme.breakpoints.up('sm')]: {
          minWidth: 'unset',
        },
      },
      headCell: {
        fontWeight: 600,
      },
      arrowCell: {
        minWidth: 10,
        width: 10,
      },
      actionCell: {
        minWidth: 25,
        width: 25,
        borderBottom: '1px solid rgba(0,0,0,0.1)',
      },
      checkBoxCell: {
        minWidth: 25,
        width: 25,
      },
      checkBox: {
        padding: 0,
      },
      arrowIcon: {
        position: 'relative',
        top: 2,
        cursor: 'pointer',
      },
      cursor: {
        cursor: 'pointer',
      },
      paginationWrapper: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
      },
      tablePagination: {
        width: '100%',
        borderBottom: 'none',
      },
      disabledButton: {
        pointerEvents: 'none',
        color: 'lightgray',
      },
    }
  },
  { name: 'productList', index: 1 }
)
