import React from 'react'
import SubpageBanner from '../components/SubpageBanner'
import { Avatar, Box, makeStyles, Theme } from '@material-ui/core'
import Title from '../../CommonModule/Title'
import { CampaignProduct } from '../components/CampaignProduct'
import useAboutPage from '../hooks/useAboutPage'
import useScroll from '../hooks/useScroll'
import Float from '../../CommonModule/Float'

const useStyles = makeStyles(
  (theme: Theme) => {
    return {
      root: {},
      section: {
        margin: '0 5%',
        width: '50%',
        flexWrap: 'wrap',
        flexBasis: 400,
      },

      /** Intro section */
      subTitle: {
        marginBlockStart: '0px',
        fontSize: 28,
      },
      statisticTitle: {
        marginBlockStart: '0px',
        fontWeight: 500,
        fontSize: 24,
      },
      statisticData: {
        marginBlockStart: '0px',
        fontWeight: 500,
        fontSize: 40,
        color: theme.palette.primary.main,
      },

      /** Banner section */
      banner: {
        width: '80%',
        height: 667,
      },

      /** Team section */
      member: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginLeft: '5%',
        marginRight: '5%',
      },
      avatar: {
        marginBlockEnd: '20px',
        width: 200,
        height: 200,
        '&:hover': {
          boxSizing: 'border-box',
          border: `3px solid ${theme.palette.primary.main}`,
        },
      },
      name: {
        marginBlockEnd: 0,
        fontSize: 20,
      },
      position: {
        marginBlockStart: 0,
        fontSize: 20,
        color: theme.palette.primary.main,
      },
    }
  },
  { name: 'AboutPage', index: 1 }
)

const Statistic: React.FC<{ title: string }> = ({ title, children }) => {
  const classes = useStyles()
  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        flexBasis={150}
        marginX='5%'
        textAlign='center'
      >
        <p className={classes.statisticTitle}>{title}</p>
        <p className={classes.statisticData}>{children}</p>
      </Box>
    </>
  )
}

const TimeLine: React.FC<{
  milestones: { title: string; desc: string; imgUrl: string }[]
}> = ({ milestones }) => {
  return (
    <>
      <Box marginBottom={5}>
        <Title text='Time line'></Title>
      </Box>
      {milestones.map((milestone, index) => {
        // const left = index % 2 === 0 ? false : true
        return <CampaignProduct key={milestone.title} item={milestone} />
      })}
    </>
  )
}

const Member: React.FC<{ name: string; img: string; position: string }> = ({
  name,
  img,
  position,
}) => {
  const classes = useStyles()

  return (
    <>
      <Avatar className={classes.avatar}></Avatar>
      <p className={classes.name}>{name}</p>
      <p className={classes.position}>{position}</p>
    </>
  )
}

const Team: React.FC<{
  members: { name: string; img: string; position: string }[]
}> = ({ members }) => {
  const classes = useStyles()
  return (
    <Box>
      <Box marginBottom={5}>
        <Title text='Our Team'></Title>
      </Box>
      <Box display='flex' marginX='5%' justifyContent='center' flexWrap='wrap'>
        {members.map((mem, index) => (
          <Float
            key={index}
            direction='right'
            timeout={500 + 500 * index}
            className={classes.member}
          >
            <Member {...mem} />
          </Float>
        ))}
      </Box>
    </Box>
  )
}

const AboutPage = () => {
  const classes = useStyles()
  const { scrollToTop } = useScroll()
  const { content } = useAboutPage()
  const intro = content?.statisticsSection?.intro || { header: '', desc: '' }
  const statistics = content?.statisticsSection?.statistics || []
  const bannerImgUrl = content?.bannerSection?.imgUrl || ''
  const milestones = content?.timelineSection?.milestones || []
  const members = content?.teamSection?.members || []

  return (
    // <Slide direction='up' timeout={1500} in={true} mountOnEnter unmountOnExit>
    <Box>
      {scrollToTop()}
      <SubpageBanner></SubpageBanner>
      <Box
        marginLeft='5%'
        marginRight='5%'
        marginBottom={10}
        display='contents'
      >
        {/* Statistics */}
        <Box display='flex' justifyContent='center' flexWrap='wrap'>
          <Box className={classes.section}>
            <p className={classes.subTitle}>{intro?.header}</p>
            <p style={{ fontSize: 20 }}>{intro?.desc}</p>
          </Box>
          <Box display='flex' className={classes.section}>
            {statistics?.map((statistic) => (
              <Statistic key={statistic.title} title={statistic.title}>
                {statistic.data}
              </Statistic>
            ))}
          </Box>
        </Box>

        {/* Banner */}
        <Box display='flex' justifyContent='center' marginTop={8}>
          <img src={bannerImgUrl} alt='Banner img' className={classes.banner} />
        </Box>

        {/* Timeline */}
        <Box marginTop={12}>
          <TimeLine milestones={milestones} />
        </Box>

        {/* Team */}
        <Box marginTop={12}>
          <Team members={members}></Team>
        </Box>
      </Box>
    </Box>
    // </Slide>
  )
}

export default AboutPage
