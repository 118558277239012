import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './ProductModule/reducers'
import { BrowserRouter } from 'react-router-dom'
import MongoDBProvider from './CommonModule/hooks/useStitch'
import RealmApp from './CommonModule/MongodbRealm/RealmApp'

const store = createStore(
  rootReducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
)
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <RealmApp>
        <MongoDBProvider>
          <App />
        </MongoDBProvider>
      </RealmApp>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
