import { makeStyles } from '@material-ui/core'

const mobileWidth = '100%'
const desktopWidth = 250
export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    padding: '0 10px 0 10px',
    [theme.breakpoints.up('sm')]: {
      padding: '0 20px 0 20px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 30px 0 30px',
    },
  },
  stickyBar: {
    display: 'flex',
    height: 50,
    top: 0,
    alignItems: 'center',
    marginBottom: 30,
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
  },
  toggleButton: {
    position: 'absolute',
    right: 20,
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      right: 30,
    },
    [theme.breakpoints.up('md')]: {
      right: 40,
    },
  },
  drawer: {
    width: mobileWidth,
    flexShrink: 0,
    position: 'relative',
    margin: 0,
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      width: desktopWidth,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 10,
    },
  },
  drawerPaper: {
    width: mobileWidth,
    position: 'relative',
    overflowY: 'unset',
    border: 'none',
    [theme.breakpoints.up('sm')]: {
      width: desktopWidth,
    },
  },
  drawerHeader: {
    flexDirection: 'column',
    display: 'block',
    overflow: 'scroll',
    alignItems: 'center',
    padding: '60px 20px 100px 30px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      padding: '20px 20px 10px 30px',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      overflow: 'unset',
      padding: '0 20px 20px 20px',
      boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
      marginBottom: 20,
    },
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    [theme.breakpoints.up('md')]: {
      marginLeft: -desktopWidth,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  filterBoxButton: {
    display: 'inline-block',
    marginTop: 20,
    padding: '10px 25px',
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    backgroundColor: theme.palette.primary.dark,
    cursor: 'pointer',
    transition: 'all ease-out 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  noResultMessage: {
    width: 'fit-content',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    marginTop: 5,
    fontSize: 20,
  },
}))
