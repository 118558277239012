import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {},
      button: {
        padding: '5px 20px',
        border: `2px solid ${theme.palette.primary.dark}`,
        width: 130,
        margin: '15px auto 0px auto',
        borderRadius: '5px',
        color: theme.palette.getContrastText(theme.palette.primary.light),
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'all ease-out 0.3s',
        '&:hover': {
          background: theme.palette.primary.main,
          border: `2px solid ${theme.palette.primary.main}`,
          color: theme.palette.getContrastText(theme.palette.primary.dark),
        },
        [theme.breakpoints.up('sm')]: {
          margin: '15px 0px 0px 0px',
        },
      },
      popupContent: {
        [theme.breakpoints.up('sm')]: {
          width: 350,
          padding: '20px 20px 20px 25px',
        },
      },
      popupButtonsWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      },
      popupSubmitButton: {
        width: '100%',
        background: theme.palette.primary.dark,
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        textAlign: 'center',
        padding: 8,
        '&:hover': {
          background: theme.palette.primary.main,
        },
      },
      popupCloseButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 8,
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
      },
      sectionLabel: {
        marginTop: 15,
        fontWeight: 600,
        textTransform: 'uppercase',
      },
      selectedCategoryName: {
        color: theme.palette.primary.main,
        marginRight: 5,
      },
    }
  },
  { name: 'categorySelector', index: 1 }
)
