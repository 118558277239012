import React, {useState, useEffect} from 'react'
import {
  Box,
  Icon,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
} from '@material-ui/core'
import LoadingSpinner from '../../CommonModule/LoadingSpinner'
import useCategories from '../../ProductModule/hooks/useCategories'
import { useStyles } from './ProductList.style'
import useProduct from '../../ProductModule/hooks/useProduct'
import { Product } from '../../ProductModule/DataModels/Products'
import NeroButton from '../../CommonModule/NeroButton/NeroButton.component'
import { Link } from 'react-router-dom'
import '../../i18n'
import { useTranslation } from 'react-i18next'

interface RowProps {
  row: Product
  handleCheckBox: Function
  selectedValues: string[]
}

function Row({ row, handleCheckBox, selectedValues}: RowProps) {
  const [open, setOpen] = React.useState(false)
  const { findCategoryById } = useCategories()
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <TableRow className={classes.cellWrapper}>
        <TableCell className={`${classes.tableCell} ${classes.checkBoxCell}`}>
          <Checkbox
            className={classes.checkBox}
            onChange={(e) => handleCheckBox(e)}
            value={row.id}
            checked={selectedValues?.includes(row.id)}
          />
        </TableCell>
        <TableCell className={`${classes.tableCell} ${classes.arrowCell}`}>
          <Icon className={classes.arrowIcon} onClick={() => setOpen(!open)}>
            {open ? 'keyboard_arrow_up_icon' : 'keyboard_arrow_down_icon'}
          </Icon>
        </TableCell>
        <TableCell className={classes.tableCell} component='th' scope='row'>
          {row.name}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {findCategoryById(row.category)?.name}
        </TableCell>
        <TableCell className={classes.tableCell}>{row.condition}</TableCell>
        <TableCell className={classes.tableCell}>{row.numberInStock}</TableCell>
        <TableCell
          className={`${classes.tableCell} ${classes.actionCell}`}
          align='right'
        >
          <Link
            style={{ textDecoration: 'none', color: '#000' }}
            to={`/cms/products/${row.id}`}
          >
            <Icon className={classes.cursor}>edit</Icon>
          </Link>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <Table
                style={{ tableLayout: 'fixed' }}
                size='small'
                aria-label='versions'
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={`${classes.headCell}`}>
                      {t('product.storageSize')}
                    </TableCell>
                    <TableCell className={`${classes.headCell}`}>
                      {t('product.color')}
                    </TableCell>
                    <TableCell className={`${classes.headCell}`}>
                      {t('product.price')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.versions.map((version, index) => (
                    <TableRow key={index}>
                      <TableCell>{version.storageSize}</TableCell>
                      <TableCell>{version.color}</TableCell>
                      <TableCell>{`${Number(
                        version.price?.value
                      ).toLocaleString()} ${
                        version.price?.currency
                      }`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles()
  const { count, page, rowsPerPage, onChangePage } = props

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box className={classes.paginationWrapper}>
      <Icon
        onClick={handleFirstPageButtonClick}
        className={page === 0 ? classes.disabledButton : ''}
      >
        first_page_icon
      </Icon>
      <Icon
        onClick={handleBackButtonClick}
        className={page === 0 ? classes.disabledButton : ''}
      >
        keyboard_arrow_left_icon
      </Icon>
      <Icon
        onClick={handleNextButtonClick}
        className={
          page >= Math.ceil(count / rowsPerPage) - 1
            ? classes.disabledButton
            : ''
        }
      >
        keyboard_arrow_right_icon
      </Icon>
      <Icon
        className={
          page >= Math.ceil(count / rowsPerPage) - 1
            ? classes.disabledButton
            : ''
        }
        onClick={handleLastPageButtonClick}
      >
        last_page_icon
      </Icon>
    </Box>
  )
}

const ProductList = (props: any) => {
  const { content, deleteProduct } = useProduct()
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedValues, setSelectedValues] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const handleDelete = async() => {
    setLoading(prev => !prev)
    for(const value of selectedValues) {
      await deleteProduct(value)
    }
    setLoading(prev => !prev)
    setSelectedValues([])
  }

  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValues((prevSelectedValues) => {
      var selectedValue = event.target.value
      if (prevSelectedValues.includes(selectedValue)) {
        return [...prevSelectedValues.filter((item) => item !== selectedValue)]
      } else {
        return [...prevSelectedValues, selectedValue]
      }
    })
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return loading ? (
    <Box
      height='90vh'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <LoadingSpinner open={true} />
    </Box>
  ) : (
    <Box>
      <Box className={classes.addDeleteButtonWrapper}>
        <Link style={{ textDecoration: 'none' }} to={`/cms/products/new`}>
          <NeroButton>{t('common.addProduct')}</NeroButton>
        </Link>
        {selectedValues.length > 0 && (
          <NeroButton onClick={handleDelete}>{t('common.delete')}</NeroButton>
        )}
      </Box>
      <Box className={classes.productTableWrapper}>
        <TableContainer>
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow>
                <TableCell
                  className={`${classes.headCell} ${classes.tableCell} ${classes.checkBoxCell}`}
                />
                <TableCell
                  className={`${classes.headCell} ${classes.tableCell} ${classes.arrowCell}`}
                />
                <TableCell
                  className={`${classes.headCell} ${classes.tableCell}`}
                >
                  {t('common.name')}
                </TableCell>
                <TableCell
                  className={`${classes.headCell} ${classes.tableCell}`}
                >
                  {t('category.category')}
                </TableCell>
                <TableCell
                  className={`${classes.headCell} ${classes.tableCell}`}
                >
                  {t('product.condition')}
                </TableCell>
                <TableCell
                  className={`${classes.headCell} ${classes.tableCell}`}
                >
                  {t('product.number')}
                </TableCell>
                <TableCell
                  className={`${classes.headCell} ${classes.tableCell} ${classes.actionCell}`}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? content.products.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : content.products
              ).map((product, index) => (
                <Row
                  key={index}
                  row={product}
                  handleCheckBox={handleCheckBox}
                  selectedValues={selectedValues}
                />
              ))}
            </TableBody>
          </Table>
          <Table>
            <TableBody>
              <TableRow>
                <TablePagination
                  className={classes.tablePagination}
                  rowsPerPageOptions={[
                    10,
                    15,
                    20,
                    { label: t('common.all'), value: -1 },
                  ]}
                  colSpan={3}
                  count={content.products.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage={t('common.rowPerPage')}
                  SelectProps={{
                    inputProps: { 'aria-label': t('common.rowPerPage') },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}
export default ProductList
