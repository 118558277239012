import { Box, TextField } from '@material-ui/core'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useRouteMatch, useHistory } from 'react-router-dom'
import { BSON } from 'realm-web'
import { useConfirm } from '../../CommonModule/Confirmation'
import NeroButton from '../../CommonModule/NeroButton'
import { Category } from '../../ProductModule/DataModels/Categories'
import useCategories from '../../ProductModule/hooks/useCategories'
import CategorySelector from '../CategorySelector/CategorySelector.component'
import { useStyles } from './CategoryEdit.style'
import LoadingSpinner from '../../CommonModule/LoadingSpinner'
import '../../i18n'
import { useTranslation } from 'react-i18next'

type FormInputs = {
  name: string
  iconName: string
  categoryGroupId: string
}

const defaultValue: FormInputs = {
  name: '',
  iconName: '',
  categoryGroupId: '',
}

const CategoryEdit = () => {
  const classes = useStyles()
  const confirm = useConfirm()
  const { id: routeId } = useParams<{ id: string }>()
  const [categoryMissing, setCategoryMissing] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const { t } = useTranslation()

  const {
    content,
    updateCategory,
    findNearestDescendants,
    findCategoryById,
  } = useCategories()

  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    setValue,
    watch,
  } = useForm<FormInputs>({
    shouldUnregister: false,
    defaultValues: defaultValue,
  })

  const history = useHistory()

  const handleSelected = (value: string) => {
    // console.log(value)
    // TODO: don't let user choose itself as the parent category
    if (value === routeId) return

    // update the selected value
    setValue('categoryGroupId', watch('categoryGroupId') === value ? '' : value)
  }

  /** Initialize inputs data from the route id. */
  React.useEffect(() => {
    // console.log(routeId)
    if (routeId !== 'new') {
      const cate = findCategoryById(routeId)
      // console.log(cate, cate?.parentId)
      setValue('name', cate?.name)
      setValue('categoryGroupId', cate?.parentId)
    }
  }, [routeId, content])

  const submitFunction = async (data: FormInputs) => {
    const fullPath = findCategoryById(data.categoryGroupId)?.fullPath
    const cate = new Category(
      routeId === 'new' ? undefined : new BSON.ObjectId(routeId),
      data.name,
      data.iconName,
      fullPath
    )
    confirm()
      .then(async () => {
        setLoading(true)
        await updateCategory(cate)
        history.goBack()
      })
      .catch(() => {})
  }

  return loading ? (
    <Box
      height='90vh'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <LoadingSpinner open={true} />
    </Box>
  ) : (
    <Box className={classes.root}>
      <form onSubmit={handleSubmit(submitFunction)}>
        <Box className={classes.formInnerWrapper}>
          <Box className={classes.sectionLabel}>{t('category.info')}:</Box>
          <TextField
            className={classes.textField}
            name='name'
            InputLabelProps={{ shrink: watch('name') !== '' || undefined }}
            type='text'
            label={
              errors.name?.type === 'required'
                ? `${t('message.doNotLeaveNameEmpty')}`
                : `${t('common.name')}`
            }
            inputRef={register({ required: true })}
            variant='outlined'
            size='small'
          />
          {/* <TextField name='iconName' type='text' inputRef={register} /> */}
          <Box className={classes.sectionLabel}>
            {t('category.chooseRootCategory')}:
          </Box>
          <CategorySelector
            labelText='Select a Category Group'
            handleChange={handleSelected}
            selectedValue={[watch('categoryGroupId')]}
          />
          <Box className={classes.warningText}>
            {categoryMissing && t('message.pleaseSelectACategory')}
          </Box>
        </Box>
        <NeroButton className={classes.submitButton} type='submit'>
          {t('common.submit')}
        </NeroButton>
      </form>
    </Box>
  )
}

export default CategoryEdit
