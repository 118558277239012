import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      imageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        margin: '15px 0',
        boxShadow: '0 0 5px 0 rgba(0,0,0,0.1)',
        maxWidth: '25%',
        [theme.breakpoints.up('sm')]: {
          maxWidth: '20%',
        },
        [theme.breakpoints.up('md')]: {
          maxWidth: 150,
        },
      },
      image: {
        width: '100%',
      },
      imageName: {
        margin: '5px 0',
        padding: '0 5px',
        fontSize: 10,
        textAlign: 'center',
        wordBreak: 'break-word',
      },
      closeButtonWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      closeButton: {
        cursor: 'pointer',
      },
    }
  },
  { name: 'imagePreviewPane', index: 1 }
)
