import { combineReducers } from 'redux'
import homePageReducer from './HomePage.reducer'
import contactPageReducer from './ContactPage.reducer'
import productReducer from './Product.reducer'
import categoryReducer from './Category.reducer'
import aboutPageReducer from './AboutPage.reducer'
import authReducer from './Auth.reducer'
import errorReducer from './Error.reducer'

const rootReducer = combineReducers({
  homePageReducer,
  contactPageReducer,
  productReducer,
  aboutPageReducer,
  categoryReducer,
  authReducer,
  errorReducer,
})
export default rootReducer
export type RootState = ReturnType<typeof rootReducer>
