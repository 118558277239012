import { ActionTypes } from "../actions"

interface Error {
  message: string | null
}
const initialState = {
  message: null
}

const errorReducer = (state = initialState, action: any) => {
  switch(action.type){
    case ActionTypes.ADD_ERROR: {
      return {...state, message: action.payload.message}
    }
    case ActionTypes.REMOVE_ERROR: {
      return {...state, message: null}
    }
    default: return state
  }
}
export default errorReducer