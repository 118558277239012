import { makeStyles, createStyles, Theme } from '@material-ui/core'

/** SECTION - JSS classes */
export const useStyles = makeStyles(
  (theme: Theme) => {
    return createStyles({
      root: {
        /** Position */
        /** Box */
        flexGrow: 1,
        padding: '40px 5% 0 5%',
        /** Typo */
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        /** Visual */
        backgroundColor: theme.palette.primary.dark,
        border: theme.palette.background.paper,
        /** Misc */
        '& $section': {
          paddingBlockStart: '35px',
          paddingBlockEnd: '35px',
        },
      },
      innerFooterWrapper: {
        width: '100%',
        maxWidth: 1920,
        margin: 'auto',
      },
      copyright: {
        paddingBlockStart: '10px',
        paddingBlockEnd: '10px',
        '& h6': {
          fontSize: 12,
        },
      },
      section: {},
      sectionColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: '1 1 210px',
        [theme.breakpoints.down('sm')]: {
          marginBlockEnd: '30px',
        },
      },
      sectionTitle: {
        fontSize: 15,
        textTransform: 'uppercase',
        letterSpacing: '0.5px',
      },
      sectionContent: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          minHeight: '100%',
        },
      },
      subscriptionTitle: {
        /** Box */
        marginBottom: 20,
        /** Typo */
        textTransform: 'uppercase',
      },
      coloredText: {
        color: theme.palette.primary.main,
      },
      input: {
        /** Position */
        // paddingLeft: 5,
        margin: theme.spacing(2),
        /** Box */
        width: 400,
        height: 40,
        /** Typo */
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        /** Visual */
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        // borderBottom: `1px solid ${mainColor}`,
        // transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',

        /** Misc */
        '& .MuiInput-underline': {
          height: '100%',
          color: 'white',
          '&:before, &:hover:before': {
            borderBottomWidth: 1,
            borderBottomColor: theme.palette.primary.main,
          },
        },
        '& input': {
          textAlign: 'center',
        },

        [theme.breakpoints.down('xs')]: {
          width: '90%',
        },
      },
      button: {
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        backgroundColor: theme.palette.primary.main,
      },
      divider: {
        height: '2px',
        backgroundColor: theme.palette.primary.main,
      },
      item: {
        /** Box */
        marginLeft: '12.5px',
        marginRight: '12.5px',
        fontSize: 15,
        lineHeight: '28px',
        letterSpacing: '0.1px',
        /** Typo */
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        /** Misc */
        transition: 'color 0.25s ease',
        '& svg>path': {
          transition: 'fill 0.25s ease',
        },
        '&:hover': {
          cursor: 'pointer',
          color: theme.palette.primary.main,
          '& svg>path': {
            fill: theme.palette.primary.main,
          },
        },
      },
    })
  },
  { name: 'footer', index: 1 }
)
