import React from 'react'
import { makeStyles, Box } from '@material-ui/core'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundImage: `url('https://images.pexels.com/photos/3803251/pexels-photo-3803251.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500')`,
      height: '300px',
      backgroundSize: 'cover',
      marginBottom: theme.spacing(10),
      [theme.breakpoints.up('md')]: {
        height: '435px',
      },
    },
    text: {
      minWidth: '320px',
      width: 'auto',
      height: '80px',
      lineHeight: '80px',
      textAlign: 'center',
      fontSize: '28px',
      fontWeight: 900,
      textTransform: 'uppercase',
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      color: 'white',
      [theme.breakpoints.up('md')]: {
        minWidth: '400px',
        height: '120px',
        lineHeight: '120px',
        fontSize: '30px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '32px',
      },
    },
  }),
  { name: 'banner', index: 1 }
)

const SubpageBanner = () => {
  const classes = useStyles()
  return (
    <>
      <Box
        className={classes.root}
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
      >
        <Box className={classes.text}>Static text</Box>
      </Box>
    </>
  )
}

export default SubpageBanner
