import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react'
import { CSSTransition } from 'react-transition-group'
import useIntersection from '../ProductModule/hooks/useIntersection';


const useStyles = makeStyles(() => {
  const transform = (
    direction: string = 'up',
    distance: number = 10
  ) => {
    const transformType = 'translate'
    switch (direction) {
      case 'up':
        return {enter: `${transformType}Y(${distance}px)`, enterActive: `${transformType}Y(0)`}
      case 'right':
        return {enter: `${transformType}X(${-distance}px)`, enterActive: `${transformType}X(0)`}
      case 'left':
        return {enter: `${transformType}X(${distance}px)`, enterActive: `${transformType}X(0)`}
    }
  }

  return {
    /** The flow of 3 states is initial -> active -> done.
     * Same for exit process.*/
    enter: (props: StyleInput) => ({
      opacity: 0,
      transform: transform(props.direction, props.offsetPosition)?.enter,
      // backgroundColor: 'red',
    }),
    enterActive: (props: StyleInput) => ({
      opacity: 1,
      transform: transform(props.direction, props.offsetPosition)?.enterActive,
      transition: `opacity ${props.timeout}ms, transform ${props.timeout}ms`,
    }),
    enterDone: (props: StyleInput) => ({
      transform: transform(props.direction, props.offsetPosition)?.enterActive,
      // backgroundColor: 'yellow',
    }),
    exit: (props: StyleInput) => ({
      opacity: 1,
      transform: transform(props.direction, props.offsetPosition)?.enterActive,
    }),
    exitActive: (props: StyleInput) => ({
      opacity: 0,
      transform: transform(props.direction, props.offsetPosition)?.enter,
      // transition: `opacity ${props.timeout}ms, transform ${props.timeout}ms`,
    }),
    exitDone: {
      opacity: 0,
    },
  }
}, {name: 'Float'})

interface StyleInput {
  // inProp: boolean
  timeout?: number

  /** Direction where component float to. */
  direction?: 'up' | 'right' | 'left'

  /** Distance that the component will translate. */
  offsetPosition?: number

  /** To trigger only once for the first time the component comes into viewport. */
  onceOnly?: boolean

  /** Offset that the threshold will calculate. */
  rootMargin?: string

  /** Base className of the children component. */
  className?: string
}

/** A HOC to animate children component when it is in view port. */
const Float: FC<StyleInput> = ({
  children,
  timeout = 350,
  direction = 'up',
  rootMargin = '0%',
  onceOnly = true,
  offsetPosition = 10,
  className = '',
}) => {
  const classes = useStyles({ direction, timeout, offsetPosition })
  const ref = React.useRef(null)
  const {isInViewPort} = useIntersection({
    elementRef: ref,
    threshold: 0.5,
    rootMargin,
    stopOnceVisible: onceOnly,
  })

  return (
    // <>
      /* Place holder to determine if the component is in view port */
      <div ref={ref} className={className}>
        <CSSTransition
          in={isInViewPort}
          timeout={timeout}
          classNames={{ ...classes }}
          mountOnEnter
        >
          {() => {
            return children
          }}
        </CSSTransition>
      </div>
    // </>
  )
}

export default Float
