import React from 'react'
import { Box, Grid } from '@material-ui/core'
import useCategories from '../../ProductModule/hooks/useCategories'
import useProduct from '../../ProductModule/hooks/useProduct'
import useAuth from '../Auth/hooks/useAuth'
import { useStyles } from './Dashboard.style'
import '../../i18n'
import { useTranslation } from 'react-i18next'

const Dashboard = (props: any) => {
  const classes = useStyles()
  const categories = useCategories().content.categories
  const products = useProduct().content.products
  const { user } = useAuth()
  const { t } = useTranslation()

  return (
    <Box className={classes.root}>
      <Box className={classes.mainTitle}>
        {t('message.welcomeToTheDashboard')},{' '}
        {user?.customData.email.substring(
          0,
          user?.customData.email.lastIndexOf('@')
        )}
      </Box>
      <Grid container>
        <Grid className={classes.box} container item xs={12} sm={6} md={4}>
          {`${t('message.currentNumberOfCategories')}: ${categories.length}`}
        </Grid>
        <Grid className={classes.box} container item xs={12} sm={6} md={4}>
          {`${t('message.currentNumberOfProducts')}: ${products.length}`}
        </Grid>
      </Grid>
    </Box>
  )
}
export default Dashboard
